import { useContext, useMemo, useState } from 'react';
import DocumentComparer from '../../../../components/DocumentComparer/DocumentComparer';
import CloseIcon from '../../../../components/icons/Close';
import Modal from '../../../../components/common/HTML_components/Modal/Modal';
import DuplicateActionButtons from './DuplicateActionButtons';
import DocumentCompareHeader from '../../../DuplicatesByDocument/DocumentCompareHeader';
import CaseContext from '../../../Case/CaseContext';
import { useCompareDocuments } from './useCompareDocuments';
import { useSyncDocumentsScroll } from '../../../../components/DocumentComparer/useSyncDocumentsScroll';
import useDisplayStore from '../../../Timeline/useDisplayStore';
import Theme from '../../../../theme';

type Props = {
  isDocumentComparerModalOpen: boolean;
  closeDocumentComparerModal: () => void;
  documentComparerDocumentIds: {
    firstDocumentID: string;
    secondDocumentID: string;
  };
  setDocumentComparerDocumentIds: (ids: {
    firstDocumentID?: string;
    secondDocumentID?: string;
  }) => void;
  isFirstComparisonDocumentDuplicate: boolean;
  isSecondComparisonDocumentDuplicate: boolean;
  handleUpdateDocumentDuplicateStatusV1Cases: (entryId: string, isDuplicate: boolean) => void;
  updateIndexRowCache: (rowId: string, updatedValues: any) => void;
};

export default function DocumentComparisonModal({
  isDocumentComparerModalOpen,
  closeDocumentComparerModal,
  documentComparerDocumentIds,
  setDocumentComparerDocumentIds,
  isFirstComparisonDocumentDuplicate,
  isSecondComparisonDocumentDuplicate,
  handleUpdateDocumentDuplicateStatusV1Cases,
  updateIndexRowCache,
}: Props) {
  const [zoom, setZoom] = useState<number>(0.8);
  const { caseInstance } = useContext(CaseContext);

  const caseVersion = useMemo(() => caseInstance?.version, [caseInstance]);

  const windowSize = useDisplayStore((state) => state.windowSize);

  const {
    isFirstDocumentDuplicate,
    isSecondDocumentDuplicate,
    updateDocumentToDuplicateV1,
    updateDocumentToNonDuplicateV1,
    markAsDuplicate,
    markAsNotDuplicate,
    isUpdateInProgress,
    documentSimilarity,
  } = useCompareDocuments(
    documentComparerDocumentIds.firstDocumentID,
    documentComparerDocumentIds.secondDocumentID,
    caseVersion,
    setDocumentComparerDocumentIds,
    handleUpdateDocumentDuplicateStatusV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    updateIndexRowCache,
  );

  const {
    areDocumentScrollsSynced,
    setAreDocumentScrollsSynced,
    setFirstDocumentPageCount,
    setSecondDocumentPageCount,
  } = useSyncDocumentsScroll(isDocumentComparerModalOpen);

  return (
    <Modal
      isOpen={isDocumentComparerModalOpen}
      onClose={closeDocumentComparerModal}
      style={{ height: '95%', overflow: 'hidden' }}
    >
      <div
        style={{
          padding: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <span
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: 'var(--color-text-primary)',
            }}
          >
            Document Comparison
          </span>
          <span>
            <button className="sm-icon-button" type="button" onClick={closeDocumentComparerModal}>
              <CloseIcon />
            </button>
          </span>
        </div>
        <hr className="sm-horizontal-divider" />
        {caseVersion === 2 && (
          <DocumentCompareHeader
            notDuplicate={() => {
              if (documentSimilarity == null) {
                return;
              }
              markAsNotDuplicate({
                document_id: String(documentSimilarity?.document_id),
                duplicate_of: String(documentSimilarity?.duplicate_of),
              });
            }}
            setAreDocumentScrollsSynced={() =>
              setAreDocumentScrollsSynced(!areDocumentScrollsSynced)
            }
          />
        )}
        {caseVersion === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <DuplicateActionButtons
              documentId={documentComparerDocumentIds.firstDocumentID}
              isDocumentDuplicate={isFirstDocumentDuplicate}
              handleUpdateDocumentToDuplicate={updateDocumentToDuplicateV1}
              handleUpdateDocumentToNonDuplicate={updateDocumentToNonDuplicateV1}
              isUpdateInProgress={isUpdateInProgress}
              style={{
                marginLeft: 'auto',
              }}
            />
            <DuplicateActionButtons
              documentId={documentComparerDocumentIds.secondDocumentID}
              isDocumentDuplicate={isSecondDocumentDuplicate}
              handleUpdateDocumentToDuplicate={updateDocumentToDuplicateV1}
              handleUpdateDocumentToNonDuplicate={updateDocumentToNonDuplicateV1}
              isUpdateInProgress={isUpdateInProgress}
              style={{
                marginRight: 'auto',
              }}
            />
          </div>
        )}
        <DocumentComparer
          isDuplicatesTab={false}
          isFirstDocumentDuplicate={isFirstDocumentDuplicate}
          isSecondDocumentDuplicate={isSecondDocumentDuplicate}
          handleMarkAsDuplicate={markAsDuplicate}
          areDocumentScrollsSynced={areDocumentScrollsSynced}
          setAreDocumentScrollsSynced={setAreDocumentScrollsSynced}
          setZoom={setZoom}
          firstDocumentID={documentComparerDocumentIds.firstDocumentID}
          secondDocumentID={documentComparerDocumentIds.secondDocumentID}
          setFirstDocumentPageCount={setFirstDocumentPageCount}
          setSecondDocumentPageCount={setSecondDocumentPageCount}
          zoom={zoom}
          firstDocumentStyle={{
            maxHeight: `${windowSize.height - windowSize.height / 5}px`,
            height: '100%',
            border: '1.5px solid',
            borderColor: isFirstDocumentDuplicate
              ? Theme.palette.duplicatesByDocumentRed.main
              : Theme.palette.duplicatesByDocumentBlue.main,
            borderRadius: '10px',
            backgroundColor: isFirstDocumentDuplicate
              ? Theme.palette.duplicatesByDocumentRed.light
              : Theme.palette.duplicatesByDocumentBlue.light,
          }}
          secondDocumentStyle={{
            maxHeight: `${windowSize.height - windowSize.height / 5}px`,
            height: '100%',
            border: '1.5px solid',
            borderColor: isSecondDocumentDuplicate
              ? Theme.palette.duplicatesByDocumentRed.main
              : Theme.palette.duplicatesByDocumentBlue.main,
            borderRadius: '10px',
            backgroundColor: isSecondDocumentDuplicate
              ? Theme.palette.duplicatesByDocumentRed.light
              : Theme.palette.duplicatesByDocumentBlue.light,
          }}
        />
      </div>
    </Modal>
  );
}
