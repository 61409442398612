export const normalizeCase = (gqlCase: any) => ({
  id: gqlCase.id,
  caseName: gqlCase.case_name,
  dueDate: gqlCase.due_date,
  dateOfBirth: gqlCase.date_of_birth,
  dateOfInjury: gqlCase.date_of_injury,
  fullName: gqlCase.full_name,
  claimReason: gqlCase.claim_reason,
  case_type_id: gqlCase.case_type_id,
});
