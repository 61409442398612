import { useQuery } from '@tanstack/react-query';
import { fetchSequenceUpdates } from '../../../api';

export function useDocumentTreeSequence(
  sequence?: bigint | null,
  caseID?: string,
  fileID?: string,
): {
  data: any;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['sequence', fileID],
    () => fetchSequenceUpdates(caseID as string, fileID as string, sequence ?? null),
    {
      enabled: fileID != null && caseID != null,
      refetchInterval: 5 * 1000, // 5 seconds
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
    },
  );

  return { data: data?.data, isLoading, isError };
}
