import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import PageNote from '../../../components/icons/PageNotes';
import LinkWithQuery from '../../Timeline/LinkWithQuery';

function PageNoteControl({ documentID, pageNumber, noteCount, sx = {} }: Props) {
  return noteCount > 0 ? (
    <LinkWithQuery to={`?notes=1&notes__docId=${documentID}&notes__pageId=${pageNumber}`} sx={sx}>
      <Tooltip title={`${noteCount} Note${noteCount !== 1 ? 's' : ''}`} placement="top">
        <Box style={{ marginLeft: '0.3rem', display: 'flex' }}>
          <PageNote width="0.9rem" height="0.9rem" />
        </Box>
      </Tooltip>
    </LinkWithQuery>
  ) : (
    <Box style={{ width: '1.2rem' }} />
  );
}

type Props = {
  documentID: string;
  pageNumber: number;
  noteCount: number;
  sx?: SxProps;
};

export default React.memo(PageNoteControl);
