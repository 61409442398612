import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Autocomplete,
  Divider,
  Paper,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import apiUrl from '../../library/utilities/apiUrl';
import { useUser } from '../../library/contexts/AuthContext';
import SearchIcon from '../../components/icons/SearchIcon';
import useFeatureFlags from '../../config/useFeatureFlags';
import { validateCaseDetails } from './utils/caseDetailsValidation';
import { useCaseTypes } from './hooks/useGetAllCaseTypes';

/**
 * Component for handling the creation of a new case
 */
export default function CaseDetails({ onSubmit, fileUploading, userEmail }) {
  const [caseName, setCaseName] = useState('');
  const [DOI, setDOI] = useState('');
  const [DOB, setDOB] = useState('');
  const [patientName, setPatientName] = useState('');
  const [claimReason, setClaimReason] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [case_type_id, setCaseTypeId] = useState(null);

  const { data: caseTypesList = [] } = useCaseTypes();

  const caseTypeNames = caseTypesList.map((caseType) => caseType.name);

  const [caseVersion, setCaseVersion] = useState(1);

  const isInternalEmail = userEmail?.split('@')[1] === 'siftmed.ca';

  const { data: { ShareCasesByDefaultEnabled = false, duplicatesByDocumentEnabled = false } = {} } =
    useFeatureFlags();
  const { user } = useUser();
  const [allUsers, setAllUsers] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([user.username]);

  useEffect(() => {
    if (duplicatesByDocumentEnabled) {
      setCaseVersion(2);
    }
  }, [duplicatesByDocumentEnabled]);

  async function handleAnalyze() {
    if (validateCaseDetails(true, caseName, dueDate, DOB, DOI)[0]) {
      await onSubmit({
        caseName,
        patientName,
        DOI,
        DOB,
        claimReason,
        dueDate,
        case_type_id,
        userIds: usersToAdd,
        version: caseVersion,
      });
    } else {
      // eslint-disable-next-line no-alert
      alert(validateCaseDetails(true, caseName, dueDate, DOB, DOI)[1]);
    }
  }

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const usersList = await axios.post(`${apiUrl}getUsersForGroup`, {
          userID: user.username,
        });
        if (isMounted) {
          setAllUsers(usersList.data);
          if (ShareCasesByDefaultEnabled) {
            setUsersToAdd(usersList.data.map((user) => user.username));
          }
        }
      } catch (err) {
        toast.error("Couldn't grab users.", {
          toastId: 'users-grab',
        });
        Sentry.captureException(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const checkSubmitStatus = (nameError = false) => {
    if (nameError) {
      return false;
    }
    return true;
  };

  const nameError = validateCaseDetails(true, caseName, dueDate, DOB, DOI);
  const readyToSubmit = checkSubmitStatus(nameError[0]);

  const addUser = (event, checkedUsername) => {
    event.preventDefault();
    if (checkedUsername === user.username) {
      return;
    }
    if (!usersToAdd.includes(checkedUsername)) {
      setUsersToAdd([...usersToAdd, checkedUsername]);
      return;
    }
    if (usersToAdd.includes(checkedUsername)) {
      setUsersToAdd(usersToAdd.filter((username) => username !== checkedUsername));
    }
  };

  const selectAll = (event) => {
    event.stopPropagation();
    if (allUsers.length === usersToAdd.length) {
      setUsersToAdd([user.username]);
    } else {
      setUsersToAdd(allUsers.map((users) => users.username));
    }
  };

  const renderPaperComponent = (paperProps) => {
    const { children } = paperProps;
    return (
      <Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '40px',
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <FormControlLabel
            name="select-all"
            label={
              <Typography
                sx={{
                  color: 'primary.light',
                  fontSize: '0.7rem',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
              >
                Select all
              </Typography>
            }
            onClick={selectAll}
            control={
              <Checkbox checked={allUsers.length === usersToAdd.length} onChange={selectAll} />
            }
          />
        </Box>
        {children}
      </Paper>
    );
  };

  return (
    <>
      <DialogContent
        sx={{
          padding: ' 16px 16px 18px ',
          border: 'none',
          overflow: 'auto',
        }}
      >
        <Box sx={createCaseStyles.contentContainer}>
          {fileUploading ? (
            <Box sx={createCaseStyles.uploadingContainer}>
              <Box sx={createCaseStyles.margin0}>
                <CircularProgress />
              </Box>
              {fileUploading && (
                <p style={createCaseStyles.alignCenter}>Please do not close the application.</p>
              )}
            </Box>
          ) : (
            <Box sx={{ width: '100%', margin: 'auto' }}>
              <Divider sx={{ margin: '8px 18px 8px 18px' }} />
              <Box sx={createCaseStyles.formRow}>
                <Typography variant="caseDialogHeader">Case Details</Typography>
                <Box sx={createCaseStyles.formFlexControl}>
                  <Box sx={createCaseStyles.labelBox}>
                    <InputLabel classes="case-dialog">
                      Case Name
                      <Typography
                        sx={{
                          display: 'inline',
                          color: 'grey',
                          opacity: '80%',
                          fontWeight: 700,
                          fontSize: '0.6rem',
                        }}
                      >
                        *Required
                      </Typography>
                    </InputLabel>
                    <TextField
                      id="caseNameField"
                      onChange={(event) => setCaseName(event.target.value)}
                      required
                      value={caseName}
                      name="caseName"
                      sx={createCaseStyles.textField}
                      autoComplete="off"
                      className="fs-exclude"
                    />
                  </Box>
                  <Box sx={{ ...createCaseStyles.labelBox, flex: '39%' }}>
                    <InputLabel classes="case-dialog">Due Date</InputLabel>
                    <TextField
                      id="dueDate"
                      type="date"
                      name="dueDate"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      InputProps={{
                        inputProps: { min: '1000-01-01', max: '2099-12-31' },
                        role: 'textbox',
                      }}
                    />
                  </Box>
                </Box>

                {caseTypesList?.length !== 0 && (
                  <FormControl sx={{ width: '17rem', marginTop: '1rem' }}>
                    <Typography sx={{ fontWeight: 700, fontSize: '0.6rem', color: '#385D9F' }}>
                      Type
                    </Typography>

                    <Select
                      value={case_type_id ?? ''}
                      onChange={(e) => setCaseTypeId(e.target.value === '' ? null : e.target.value)}
                      width="12rem"
                      labelId="case_type_id"
                      displayEmpty
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      {caseTypesList.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
              <Box sx={createCaseStyles.formRow}>
                <Typography variant="caseDialogHeader">Claimant Details</Typography>
                <Box sx={createCaseStyles.formFlexControl}>
                  <Box sx={createCaseStyles.labelBox}>
                    <InputLabel classes="case-dialog">Claimant's Name</InputLabel>
                    <TextField
                      id="patientNameField"
                      name="patientName"
                      sx={createCaseStyles.textField}
                      value={patientName}
                      onChange={(e) => setPatientName(e.target.value)}
                      className="fs-exclude"
                    />
                  </Box>
                  <Box sx={{ ...createCaseStyles.labelBox, flex: '39%' }}>
                    <InputLabel classes="case-dialog">Date of Birth</InputLabel>
                    <TextField
                      id="birthDate"
                      type="date"
                      name="birthDate"
                      value={DOB}
                      onChange={(e) => setDOB(e.target.value)}
                      InputProps={{
                        inputProps: { min: '1000-01-01', max: '2099-12-31' },
                        role: 'textbox',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={createCaseStyles.formRow}>
                <Typography variant="caseDialogHeader">Incident Details</Typography>
                <Box sx={createCaseStyles.formFlexControl}>
                  <Box sx={createCaseStyles.labelBox}>
                    <InputLabel classes="case-dialog">Reason For Claim</InputLabel>
                    <TextField
                      id="incidentField"
                      name="claimReason"
                      value={claimReason}
                      sx={createCaseStyles.textField}
                      onChange={(e) => setClaimReason(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ ...createCaseStyles.labelBox, flex: '39%' }}>
                    <InputLabel classes="case-dialog">Date of Incident</InputLabel>
                    <TextField
                      id="inciDate"
                      type="date"
                      name="injuryDate"
                      value={DOI}
                      onChange={(e) => setDOI(e.target.value)}
                      InputProps={{
                        inputProps: { min: '1000-01-01', max: '2099-12-31' },
                        role: 'textbox',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {isInternalEmail && (
                <Box sx={{ ...createCaseStyles.formRow, marginTop: '2rem' }}>
                  <FormControl
                    sx={{
                      width: '15rem',
                    }}
                  >
                    <InputLabel
                      id="caseVersion"
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 700,
                        color: 'var(--color-label-blue)',
                      }}
                    >
                      Case Version
                    </InputLabel>
                    <Select
                      value={caseVersion}
                      label="Case Version"
                      onChange={(e) => setCaseVersion(e.target.value)}
                      width="12rem"
                      labelId="caseVersion"
                    >
                      {[
                        { value: 1, label: 'One' },
                        { value: 2, label: 'Two (Duplicates by Document)' },
                      ].map((version) => (
                        <MenuItem key={version.value} value={version.value}>
                          {version.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {!nameError[0] ? <Box sx={createCaseStyles.errorDisplay}>{nameError[1]}</Box> : ''}
              <Divider sx={{ margin: '18px' }} />
              <Box
                sx={createCaseStyles.formRow}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: '0.9rem',
                      color: 'primary.light',
                      fontWeight: 700,
                      marginBottom: '2px',
                    }}
                  >
                    Share Case Access
                  </InputLabel>
                  <Box sx={createCaseStyles.labelBox}>
                    <Typography
                      variant="caseDialogHeader"
                      display="inline"
                      sx={{ marginBottom: '5px' }}
                    >
                      Please select the team members below to share access.
                    </Typography>
                    <Autocomplete
                      sx={{ marginBottom: '-6rem' }}
                      freeSolo
                      forcePopupIcon
                      options={allUsers}
                      getOptionLabel={(user) => `${user.given_name} ${user.family_name}`}
                      renderOption={(props, newUser) => (
                        <Stack
                          direction="row"
                          key={newUser.username}
                          sx={{
                            marginTop: '-1rem',
                            marginBottom: '-1rem',
                          }}
                        >
                          <FormControlLabel
                            name={newUser.username}
                            label={
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                fontSize="0.7rem"
                                sx={{
                                  marginTop: '-5rem',
                                  marginBottom: '-5rem',
                                  opacity:
                                    Boolean(
                                      newUser.username === user.username ||
                                        usersToAdd.includes(newUser.username),
                                    ) && '50%',
                                }}
                                onClick={(e) => addUser(e, newUser.username)}
                              >
                                {newUser.given_name} {newUser.family_name}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                size="small"
                                checked={
                                  newUser.username === user.username ||
                                  usersToAdd.includes(newUser.username)
                                }
                                onChange={(e) => addUser(e, newUser.username)}
                              />
                            }
                          />
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="search"
                          type="new-search"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          placeholder="Search"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <SearchIcon sx={{ color: 'primary' }} />
                              </Stack>
                            ),
                          }}
                        />
                      )}
                      PaperComponent={renderPaperComponent}
                      ListboxProps={{
                        sx: {
                          maxHeight: '4.5rem',
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '3.5rem' }}>
        {!fileUploading && (
          <Button
            onClick={handleAnalyze}
            disabled={readyToSubmit || fileUploading || caseName === ''}
            size="small"
            aria-label="start case"
            variant="contained"
          >
            Start Case
          </Button>
        )}
      </DialogActions>
    </>
  );
}

const createCaseStyles = {
  textField: {
    flex: '59%',
    marginRight: '1rem',
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    flex: '59%',
  },
  iconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  uploadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  margin0: {
    margin: '0 auto',
  },
  alignCenter: {
    textAlign: 'center',
  },
  errorDisplay: {
    color: 'red',
    fontSize: 12,
    marginTop: '0.6rem',
    textAlign: 'center',
  },
  formFlexControl: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  formRow: {
    marginTop: '0.5rem',
    width: '100%',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
};
