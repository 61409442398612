import React, { useMemo, useRef } from 'react';
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  TextField,
  createFilterOptions,
} from '@mui/material';
import AutoCompleteRenderOption from '../../components/common/Autocomplete/AutoCompleteRenderOption';

interface FilterInput {
  value: string;
  label?: string;
  source: string;
  isNewValue?: boolean;
}

const entityLabelByEntityName = {
  author: 'Author',
  organization: 'Organization',
  subject: 'Subject',
  'document type': 'Document Type',
  speciality: 'Specialtie',
};

export default function EntitySelect({
  entityName,
  valueID,
  optionsForCase,
  optionsForEntry,
  setValues,
  allOptions,
  allowNewOptions = true,
  inputProps,
  className = '',
}) {
  const filter = createFilterOptions<FilterInput>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const entityLabel = useMemo(
    () => entityLabelByEntityName[entityName] ?? 'Entities',
    [entityName],
  );

  const handleInputChange = (_, newValue) => {
    const existingOption = allOptions?.find((option) => option.value === newValue);

    if (newValue && !existingOption) {
      setValues({ id: null, name: newValue, label: newValue });
    } else if (existingOption) {
      setValues({ id: existingOption.id, name: null, label: newValue });
    } else if (newValue === '') {
      setValues({ id: null, name: null, label: '' });
    }
  };

  const groupBySource = (option) => option.source;

  const selectedValue = useMemo(() => {
    return allOptions?.find((option) => option.id === valueID);
  }, [valueID, allOptions]);

  const groupedOptions = useMemo(() => {
    const allOptions = [];

    if (optionsForEntry) {
      optionsForEntry.forEach((option) => {
        allOptions.push({ ...option, source: `Suggested ${entityLabel}s` });
      });
    }
    if (optionsForCase) {
      optionsForCase.forEach((option) => {
        allOptions.push({ ...option, source: `Other ${entityLabel}s` });
      });
    }

    return allOptions;
  }, [optionsForCase, optionsForEntry]);

  const selectedValueInGroupedOptions = useMemo(() => {
    return groupedOptions.find((option) => option.value === selectedValue?.value) ?? null;
  }, [groupedOptions, selectedValue]);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <FormControl
      sx={{
        width: '100%',
        mb: '1.1rem',
      }}
    >
      <Autocomplete
        freeSolo
        className={className}
        autoHighlight
        options={groupedOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const matchingOption = options.find((option) => option.value === params.inputValue);

          if (matchingOption || params.inputValue === '' || !allowNewOptions) {
            return filtered;
          }

          filtered.push({
            value: params.inputValue,
            source: `New ${entityLabel}`,
            isNewValue: true,
          });
          return filtered;
        }}
        groupBy={groupBySource}
        value={selectedValueInGroupedOptions}
        sx={{
          '& .MuiInputBase-root': {
            height: '2.3rem',
            fontSize: '0.8rem',
          },
          '& .MuiAutocomplete-endAdornment': {
            mt: '-0.15rem',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ style: { fontSize: '0.8rem', opacity: '60%' } }}
            sx={{
              '& .MuiOutlinedInput-input': {
                mt: '-0.5rem',
              },
            }}
            onKeyDown={handleInputKeyDown}
            onFocus={() => {
              handleFocus();
              if (inputProps?.onFocus) {
                inputProps.onFocus();
              }
            }}
            onBlur={inputProps?.onBlur}
            inputRef={inputRef}
            label={`Enter ${entityName}`}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <AutoCompleteRenderOption
              option={option}
              selected={selected}
              onClick={() => setValues({ id: option.id, name: option.id ? null : option.value })}
            />
          );
        }}
        renderGroup={(params) => [
          <Box
            key={params.key}
            sx={{
              fontSize: '0.9rem',
              ml: '0.5rem',
              mt: '0.5rem',
              color: 'dropdown.subgroupHeader',
            }}
          >
            {params.group}
          </Box>,
          <Divider sx={{ color: 'dropdown.subgroupHeader' }} />,
          params.children,
        ]}
        getOptionLabel={(option) => (option.value ? String(option.value) : '')}
        onInputChange={handleInputChange}
      />
    </FormControl>
  );
}
