import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import { useNavigate } from 'react-router-dom';
import PageCardDuplicate from '../../../components/icons/PageCardDuplicate';

function PageDuplicateControl({
  pageID,
  caseID,
  timelineID,
  isUnconfirmedDuplicate,
}: {
  pageID: number;
  caseID: string;
  timelineID: string;
  isUnconfirmedDuplicate: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const navigate = useNavigate();

  const navigateToDuplicates = useCallback(() => {
    let params = pageID ? `?pageID=${pageID}` : '';
    if (params.length > 0 && timelineID) {
      params += `&timelineID=${timelineID}`;
    } else if (timelineID) {
      params += `?timelineID=${timelineID}`;
    }
    navigate(`/case/${caseID}/duplicates${params}`);
  }, [timelineID, caseID, pageID]);

  const infoCardOpen = Boolean(anchorEl);

  const openInfoCard = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isUnconfirmedDuplicate && (
        <Tooltip title="Unconfirmed Duplicate" placement="top">
          <Box display="flex" sx={{ marginLeft: '0.3rem' }}>
            <PageCardDuplicate
              width="0.9rem"
              height="0.9rem"
              style={{ cursor: 'pointer' }}
              color="#B42318"
              onClick={openInfoCard}
            />
          </Box>
        </Tooltip>
      )}

      {anchorEl !== null && (
        <Popover
          id="simple-popover"
          open={infoCardOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Card variant="outlined" style={{ maxWidth: 425 }}>
            <CardHeader
              title={
                <Typography sx={{ color: 'themeDuplicates.dark' }} variant="h6">
                  Page Marked As Duplicate
                </Typography>
              }
            />

            <CardActions style={{ float: 'right' }} onClick={() => navigateToDuplicates()}>
              <Button sx={{ fontWeight: 600 }} variant="contained" size="small">
                Handle Duplicates
              </Button>
            </CardActions>
          </Card>
        </Popover>
      )}
    </>
  );
}

export default React.memo(PageDuplicateControl);
