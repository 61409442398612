import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getUsersForAdmin,
  resetPasswordForUser,
  createNewUser,
  deleteUser,
  updateUser,
  getUserGroups,
} from '../../../api';

export function useAdminUsers() {
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(['getUsersForAdmin'], getUsersForAdmin, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const { data: userGroups } = useQuery(['getUserGroups'], getUserGroups, {
    staleTime: 1000 * 60 * 10,
  });

  const resetPasswordMutation = useMutation(
    ({ userId, email }) => resetPasswordForUser(userId, email),
    {
      onSuccess: () => {
        toast.success('Temporary password set and emailed successfully.');
        queryClient.invalidateQueries(['getUsersForAdmin']);
      },
      onError: () => {
        toast.error('Failed to reset password. Please try again.');
      },
    },
  );

  const resetPassword = (userId, email) => {
    resetPasswordMutation.mutate({ userId, email });
  };

  const createNewUserMutation = useMutation((newUser) => createNewUser(newUser), {
    onSuccess: () => {
      toast.success('User created successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    },
    onError: () => {
      toast.error('Failed to create user. Please try again.');
    },
  });

  const createNewUserFunc = (newUser) => {
    createNewUserMutation.mutate(newUser);
  };

  const deleteUserMutation = useMutation((userId) => deleteUser(userId), {
    onSuccess: () => {
      toast.success('User deleted successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    },
    onError: () => {
      toast.error('Failed to delete user. Please try again.');
    },
  });

  const deleteUserFunc = (userId) => {
    deleteUserMutation.mutate(userId);
  };

  const updateUserMutation = useMutation(
    ({ userId, given_name, family_name }) => updateUser(userId, given_name, family_name),
    {
      onSuccess: () => {
        toast.success('User updated successfully.');
        queryClient.invalidateQueries(['getUsersForAdmin']);
      },
      onError: () => {
        toast.error('Failed to update user. Please try again.');
      },
    },
  );

  const updateUserFunc = async (userId, updatedFields) => {
    if (
      ('given_name' in updatedFields && !updatedFields.given_name?.trim()) ||
      ('family_name' in updatedFields && !updatedFields.family_name?.trim())
    ) {
      toast.error('First Name and Last Name cannot be empty.');
      return;
    }

    const existingUser = data?.data?.find((user) => user.user_id === userId);

    if (!existingUser) {
      console.error('User not found for update.');
      return;
    }

    const fieldsToUpdate = Object.keys(updatedFields).reduce((acc, key) => {
      if (updatedFields[key] !== existingUser[key]) {
        acc[key] = updatedFields[key];
      }
      return acc;
    }, {});

    if (Object.keys(fieldsToUpdate).length === 0) {
      return;
    }

    try {
      await updateUser({ userId, ...fieldsToUpdate });
      toast.success('User updated successfully.');
      queryClient.invalidateQueries(['getUsersForAdmin']);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user. Please try again.');
    }
  };

  return {
    data: data?.data,
    isLoading,
    isError,
    resetPassword,
    createNewUserFunc,
    deleteUserFunc,
    updateUserFunc,
    userGroups,
  };
}
