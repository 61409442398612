import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import './dropdown-select.css';
import Theme from '../../../../theme';

type MenuPosition = {
  top: number;
  left: number;
} | null;

type Option = {
  label: string;
  value: string;
};

type CustomSelectMenuProps = {
  options: Option[];
  onChange: (option: Option) => void;
  currentOption: Option | null;
  onCommit: () => void;
  className: string;
};

function CustomSelectMenu({
  options,
  onChange,
  currentOption,
  onCommit,
  className = '',
}: CustomSelectMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(currentOption);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = anchorRef.current ? anchorRef.current?.getBoundingClientRect() : null;
      if (rect) {
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    };

    // Reports should be refactored to not be scrollable, so a second event listener is not needed
    document.getElementById('app-container')?.addEventListener('scroll', handleScroll);
    document
      .getElementById('scrollable-report-container')
      ?.addEventListener('scroll', handleScroll);

    // Remove the event listeners when the component unmounts
    return () => {
      document.getElementById('app-container')?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('scrollable-report-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const rect = anchorRef.current ? anchorRef.current.getBoundingClientRect() : null;

    if (isOpen && rect) {
      setMenuPosition({
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedOption(currentOption);
  }, [currentOption]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const menu = menuPosition != null && (
    <div
      className="dropdown-list"
      style={{
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
      }}
    >
      {options.map((option) => (
        <div
          key={option.value}
          style={{
            backgroundColor:
              selectedOption?.value === option.value
                ? 'var(--color-selected-grey-main)'
                : 'default',
          }}
          className="dropdown-item"
          onClick={() => handleSelect(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );

  const iconStyle = {
    width: '20px',
    height: '20px',
    color: Theme.palette.subHeading.main,
  };

  const arrow = isOpen ? (
    <KeyboardArrowUp style={iconStyle} />
  ) : (
    <KeyboardArrowDown style={iconStyle} />
  );

  return (
    <div className={`dropdown ${className}`} ref={anchorRef} onBlur={onCommit}>
      <div className="dropdown-select" onClick={() => setIsOpen(!isOpen)}>
        <span>{selectedOption ? selectedOption.label : 'Select item'}</span>
        {arrow}
      </div>
      {isOpen && ReactDOM.createPortal(menu, document.body)}
    </div>
  );
}

export default CustomSelectMenu;
