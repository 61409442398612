import { useMutation } from '@tanstack/react-query';
import { useApolloClient, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { updatePageHiddenStatus } from '../../../api';

export function useUpdatePageHidden() {
  const apolloClient = useApolloClient();

  const mutation = useMutation(updatePageHiddenStatus, {
    onMutate: async ({ pageID, hidden }: { pageID: bigint; hidden: boolean }) => {
      const cacheID = apolloClient.cache.identify({ __typename: 'PageObject', id: pageID });

      const page = apolloClient.readFragment({ id: cacheID, fragment: PAGE_HIDDEN });

      apolloClient.writeFragment({
        id: cacheID,
        fragment: PAGE_HIDDEN,
        data: { ...page, is_hidden: hidden ? 1 : 0 },
      });
    },
    onSuccess: () => {
      toast.success(`Page hidden status updated successfully`);
    },
    onError: () => {
      toast.error(`Failed to update page hidden status`);
    },
  });

  return {
    mutateAsync: mutation.mutateAsync,
  };
}

const PAGE_HIDDEN = gql`
  fragment PageHiddenStatusFragment on PageObject {
    is_hidden
  }
`;
