import React from 'react';

export default function IconGroup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
    >
      <path
        d="M12.9033 5.03183C12.9033 3.94786 12.9033 3.40587 12.6924 2.99185C12.5068 2.62767 12.2107 2.33157 11.8465 2.14601C11.4325 1.93506 10.8905 1.93506 9.80655 1.93506H5.67752C4.59355 1.93506 4.05156 1.93506 3.63754 2.14601C3.27336 2.33157 2.97727 2.62767 2.7917 2.99185C2.58075 3.40587 2.58075 3.94786 2.58075 5.03183V11.7415C2.58075 12.8255 2.58075 13.3675 2.7917 13.7815C2.97727 14.1457 3.27336 14.4418 3.63754 14.6273C4.05156 14.8383 4.59355 14.8383 5.67752 14.8383H7.09688M6.45171 7.74151H5.16139M5.67752 10.3221H5.16139M10.3227 5.16086H5.16139"
        strokeWidth="1.29032"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5483 10.9676H9.67738M10.9677 13.5482H9.67738M14.8387 8.38694H9.67738M17.4193 8.25791V14.9676C17.4193 16.0516 17.4193 16.5935 17.2084 17.0076C17.0228 17.3717 16.7267 17.6678 16.3625 17.8534C15.9485 18.0644 15.4065 18.0644 14.3225 18.0644H10.1935C9.10954 18.0644 8.56756 18.0644 8.15353 17.8534C7.78935 17.6678 7.49326 17.3717 7.3077 17.0076C7.09674 16.5935 7.09674 16.0516 7.09674 14.9676V8.25791C7.09674 7.17393 7.09674 6.63195 7.3077 6.21792C7.49326 5.85374 7.78935 5.55765 8.15353 5.37209C8.56756 5.16113 9.10954 5.16113 10.1935 5.16113H14.3225C15.4065 5.16113 15.9485 5.16113 16.3625 5.37209C16.7267 5.55765 17.0228 5.85374 17.2084 6.21792C17.4193 6.63195 17.4193 7.17393 17.4193 8.25791Z"
        strokeWidth="1.29032"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
