import { useState, useMemo, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Tooltip,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ControlPoint, Delete, Edit, Check, Close } from '@mui/icons-material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import PageHeader from '../../components/common/PageHeader';
import { useUser } from '../../library/contexts/AuthContext';
import AdminHeader from './AdminHeader';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useAdminUsers } from './api-hooks/useAdminUsers';

function UserManagement() {
  const { user } = useUser();
  const {
    data: userList,
    resetPassword,
    createNewUserFunc,
    deleteUserFunc,
    updateUserFunc,
    userGroups,
  } = useAdminUsers();
  const windowSize = useDisplayStore((state) => state.windowSize);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({ first_name: '', last_name: '', email: '' });

  const [selectedGroup, setSelectedGroup] = useState('');

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const openDeleteDialog = useCallback((user) => {
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  }, []);

  const handleDeleteUser = useCallback(
    async (userId) => {
      const idToDelete = userId || selectedUser?.id;
      if (!idToDelete) {
        return;
      }

      try {
        await deleteUserFunc(idToDelete);
        setDeleteUserDialogOpen(false);
        setSelectedUser(null);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    [deleteUserFunc, selectedUser],
  );

  const isAuthorized = user?.attributes?.email === 'support@siftmed.ca';

  const openDialog = useCallback((user) => {
    setSelectedUser(user);
    setDialogOpen(true);
    setSuccessMessage('');
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
    setSelectedUser(null);
    setSuccessMessage('');
    setLoading(false);
  }, []);

  const confirmResetPassword = useCallback(async () => {
    if (!selectedUser) {
      return;
    }

    setLoading(true);
    try {
      await resetPassword(selectedUser.id, selectedUser.email);
      setTimeout(() => {
        setSuccessMessage(
          `Temporary password has been sent to ${selectedUser.given_name} ${selectedUser.family_name}`,
        );
      }, 500);
    } catch (error) {
      console.error('Error resetting password:', error);
      setSuccessMessage('Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [resetPassword, selectedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateUser = async () => {
    if (!selectedGroup) {
      console.error('Group must be selected before creating a user');
      toast.error('Please select a group before adding a user.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newUser.email)) {
      toast.error('Invalid email format. Please enter a valid email.');
      return;
    }

    const userData = {
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      email: newUser.email,
      group_name: selectedGroup,
    };

    try {
      await createNewUserFunc(userData);
      setAddUserDialogOpen(false);
      setNewUser({ first_name: '', last_name: '', email: '', group: '' });
      setSelectedGroup('');
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error('Failed to create user. Please try again.');
    }
  };

  const rows = useMemo(
    () =>
      userList?.map((user) => ({
        id: user.user_id,
        given_name: user.given_name,
        family_name: user.family_name,
        email: user.email,
        user_groups: user.user_groups || [],
        account_status: user.last_login ? 'activated' : 'inactive',
      })) || [],
    [userList],
  );

  const columns = useMemo(
    () => [
      {
        field: 'given_name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params) => (
          <EditUserData
            defaultValue={params.value}
            onSave={(value) => updateUserFunc(params.row.id, { given_name: value })}
          />
        ),
      },
      {
        field: 'family_name',
        headerName: 'Surname',
        flex: 1,
        renderCell: (params) => (
          <EditUserData
            defaultValue={params.value}
            onSave={(value) => updateUserFunc(params.row.id, { family_name: value })}
          />
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1.5,
        renderCell: (params) => <Typography sx={userStyles.text}>{params.value}</Typography>,
      },

      {
        field: 'user_groups',
        headerName: 'User Group',
        flex: 1.5,
        sortable: true,
        renderCell: (params) => <Typography sx={userStyles.text}>{params.value}</Typography>,
      },
      {
        field: 'account_status',
        headerName: 'Account Status',
        flex: 1,
        sortable: true,
        renderCell: (params) => (
          <Chip
            label={params.value === 'activated' ? 'Activated' : 'Inactive'}
            variant="outlined"
            sx={{
              borderColor: params.value === 'activated' ? 'secondary.main' : 'warning.main',
              color: params.value === 'activated' ? 'secondary.main' : 'warning.main',
              borderRadius: '8px',
              fontSize: '0.8rem',
              minWidth: '80px',
            }}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              sx={{ fontSize: '0.8rem', mr: '0.5rem' }}
              onClick={() => openDialog(params.row)}
            >
              Reset Password
            </Button>
            <Tooltip title="Delete User" placement="top">
              <Delete
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => openDeleteDialog(params.row)}
              />
            </Tooltip>
          </>
        ),
      },
    ],
    [openDialog, updateUserFunc],
  );

  if (!isAuthorized) {
    return <div>Unauthorized. Please contact SiftMed support</div>;
  }

  const renderDialogContent = () => {
    if (successMessage) {
      return <SuccessContent message={successMessage} />;
    }
    return <ConfirmationContent />;
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
      <AdminHeader />
      <PageHeader title="User Management Dashboard" />
      <Button
        variant="contained"
        sx={{
          marginTop: '1rem',
          backgroundColor: 'primary.light',
          display: 'flex',
          marginBottom: '0.8rem',
        }}
        onClick={() => setAddUserDialogOpen(true)}
        startIcon={
          <ControlPoint fontSize="small" sx={{ marginTop: '-0.1rem', marginRight: '0.2rem' }} />
        }
      >
        Add New User
      </Button>
      <Box sx={userStyles.displayContainer}>
        {userList?.length ? (
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={rows}
            pageSizeOptions={[5, 10, 50, 100]}
            sx={{
              width: windowSize.width ? `${windowSize.width - 150}px` : '100%',
              border: '1px solid #00214733',
              borderRadius: '8px',
              fontSize: '0.8rem',
              boxShadow: 'none',
              backgroundColor: 'white',
              '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
            }}
          />
        ) : (
          <Box sx={{ margin: 'auto' }}>
            <Typography variant="h5">No Users</Typography>
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" disabled={loading}>
            {successMessage ? 'Close' : 'Cancel'}
          </Button>
          {!successMessage && (
            <Button onClick={confirmResetPassword} color="primary" disabled={loading}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={deleteUserDialogOpen} onClose={() => setDeleteUserDialogOpen(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this user? This action will permanently delete the user.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteUserDialogOpen(false)}>Close</Button>
          <Button onClick={() => handleDeleteUser()}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addUserDialogOpen}
        onClose={() => setAddUserDialogOpen(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '450px',
            maxWidth: '80%',
          },
        }}
      >
        <DialogTitle sx={{ paddingTop: '1rem' }}>Add New User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <input
              style={userStyles.inputContainer}
              type="text"
              name="first_name"
              placeholder="First Name"
              value={newUser.first_name}
              onChange={handleInputChange}
            />
            <input
              style={userStyles.inputContainer}
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={newUser.last_name}
              onChange={handleInputChange}
            />
            <input
              style={userStyles.inputContainer}
              type="email"
              name="email"
              placeholder="Email"
              value={newUser.email}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel id="group-select-label">Select a User Group</InputLabel>
              <Select
                labelId="group-select-label"
                value={selectedGroup}
                onChange={(event) => handleGroupChange(event)}
                label="Select a User Group"
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <MenuItem value="" disabled>
                  Select a user group
                </MenuItem>
                {userGroups?.data?.map((group) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleCreateUser()}
            disabled={!newUser.first_name || !newUser.last_name || !newUser.email || !selectedGroup}
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

function SuccessContent({ message }) {
  return (
    <Typography sx={userStyles.text} color="success.main">
      {message}
    </Typography>
  );
}

function ConfirmationContent() {
  return (
    <Typography sx={userStyles.text}>
      Please confirm to reset the password and send an email.
    </Typography>
  );
}

export default UserManagement;

const userStyles = {
  title: { fontSize: '1.875rem', display: 'inline-block', fontWeight: 500 },
  subtitle: { fontSize: '1rem' },
  text: { fontSize: '0.8rem', wordSpace: 'normal', wordBreak: 'break-word' },
  container: { marginTop: '2rem', marginBottom: '2rem' },
  casesContainer: { marginTop: '2rem' },
  loadingContainer: { display: 'flex', justifyContent: 'center', marginTop: '10px' },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    width: '100%',
    marginTop: '2rem',
  },
  inputContainer: {
    width: '100%',
    borderRadius: '8px',
    padding: '0.5rem',
    border: '1px solid #00214733',
  },
};

function EditUserData({ defaultValue, onSave }) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleSave = () => {
    if (value !== defaultValue) {
      onSave(value);
    }
    setIsEditing(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }}
      onMouseEnter={(e) => {
        const icon = e.currentTarget.querySelector('.edit-icon');
        if (icon) {
          icon.style.visibility = 'visible';
        }
      }}
      onMouseLeave={(e) => {
        const icon = e.currentTarget.querySelector('.edit-icon');
        if (icon) {
          icon.style.visibility = 'hidden';
        }
      }}
    >
      {isEditing ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            onBlur={handleSave}
            autoFocus
            sx={{ flex: 1 }}
          />
          <IconButton onClick={handleSave} size="small" color="primary">
            <Check />
          </IconButton>
        </div>
      ) : (
        <>
          <span style={{ flex: 1 }} onClick={() => setIsEditing(true)}>
            {defaultValue || '-'}
          </span>
          <IconButton
            size="small"
            color="default"
            className="edit-icon"
            onClick={() => setIsEditing(true)}
            sx={{
              position: 'absolute',
              right: 0,
              visibility: 'hidden',
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </>
      )}
    </div>
  );
}
