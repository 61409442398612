import { Box, Menu, Typography, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useCallback, useEffect, useRef } from 'react';
import { formatSegmentDate, isValidDate } from '../../../../library/utilities/useDates';

export default function DateEditCell(params: Props) {
  const {
    entryID,
    entryStartDate,
    orientation,
    isPopUp = true,
    shouldOpenByDefault = false,
    hideTitle = false,
  } = params;

  const handleIndexInputChange = useCallback(
    (newStartDate: string) => {
      const { api, id, field } = params;

      if (api == null || id == null || field == null) {
        return;
      }

      const startDateString = newStartDate ?? entryStartDate;

      api.setEditCellValue({ id, field, value: startDateString });
      api.stopCellEditMode({ id, field });
    },
    [params, entryStartDate],
  );

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const anchorRef = useRef(null);
  const [startDate, setStartDate] = useState<string>(entryStartDate);

  const openMenu = useCallback((e: any) => {
    setAnchorEl(e.target);
  }, []);

  useEffect(() => {
    if (shouldOpenByDefault) {
      openMenu({ target: anchorRef.current });
    }
  }, [shouldOpenByDefault]);

  useEffect(() => {
    setStartDate(entryStartDate);
  }, [entryStartDate]);

  const onMenuClose = useCallback(async () => {
    setAnchorEl(null);
    const newStartDate = startDate;

    handleIndexInputChange(newStartDate);
  }, [startDate, entryStartDate, entryID]);

  const display = formatSegmentDate(entryStartDate);

  const handleDateChange = useCallback(
    (newDate: Dayjs) => {
      setStartDate(isValidDate(newDate) ? newDate.format('YYYY-MM-DD') : startDate);
    },
    [startDate],
  );

  const embeddedDateRangeComponent = (
    <Box
      sx={{
        padding: '.5rem',
        marginTop: '-4px',
        ...(orientation === 'horizontal' && { width: '14rem' }),
      }}
    >
      {!hideTitle && (
        <Typography variant="h5" sx={{ m: '0.5rem' }}>
          Edit Date
        </Typography>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          direction={orientation === 'horizontal' ? 'row' : 'column'}
          sx={{ paddingX: '0.5rem' }}
          spacing={2}
        >
          <Box width="10rem">
            <DatePicker
              label="Start Date"
              format="YYYY-MM-DD"
              value={
                startDate != null && startDate !== '1900-01-01'
                  ? dayjs(startDate?.split(' ')[0])
                  : ''
              }
              minDate={dayjs('1000-01-01')}
              maxDate={dayjs('2099-12-31')}
              onChange={(e) => handleDateChange(dayjs(e))}
            />
            <Typography
              sx={{
                paddingBottom: '.5rem',
                mt: '0.5rem',
                fontSize: '12px',
                color: 'primary.light',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={() => setStartDate('1900-01-01')}
            >
              Set as Unknown
            </Typography>
          </Box>
        </Stack>
      </LocalizationProvider>
    </Box>
  );

  if (!isPopUp) {
    return embeddedDateRangeComponent;
  }
  return (
    <Box onClick={(e) => e.preventDefault()}>
      <Box onClick={openMenu} ref={anchorRef} sx={{ cursor: 'pointer', alignItems: 'center' }}>
        <Typography
          // @ts-ignore
          variant="h7"
          sx={{
            color: display !== 'Invalid date' ? 'text.light' : 'text.disabled',
            '&:hover': {
              color: 'primary.light',
            },
          }}
          className="unmask"
        >
          {display === 'Invalid date' ? 'No Date' : display}
        </Typography>
      </Box>
      {anchorEl && (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onMenuClose}>
          {embeddedDateRangeComponent}
        </Menu>
      )}
    </Box>
  );
}

type Props = {
  entryID: number;
  entryStartDate: string;
  orientation: 'horizontal' | 'vertical';
  isPopUp: boolean;
  shouldOpenByDefault: boolean;
  hideTitle?: boolean;
} & Partial<GridRenderCellParams>;
