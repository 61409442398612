import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { AddCircleOutline, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import Chip from '../Chip/Chip';
import { mapCompare, compareAlphaNumeric } from '../../../../utils/compare';
import './multi-select-dropdown.css';
import Theme from '../../../../theme';
import CloseIcon from '../../../icons/Close';
import InputWithIcon from '../InputWithIcon/InputWithIcon';
import SearchIcon from '../../../icons/SearchIcon';

type MenuPosition = {
  top: number;
  left: number;
} | null;

type Option = {
  label: string;
  value: string | null;
};

type SelectableOption = Option & {
  isSelected: boolean;
};

type Props = {
  selectedValues: Option[];
  options: Option[];
  onChange: (newOptions: Option[]) => void;
  style?: React.CSSProperties;
  dropdownHeader?: string;
};

export default function MultiSelectFreeFormDropdown({
  selectedValues,
  options,
  onChange,
  style,
  dropdownHeader,
  className = '',
}: Props) {
  const [open, setOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(null);
  const [filteredOptions, setFilteredOptions] = useState<SelectableOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const anchorRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = anchorRef.current ? anchorRef.current?.getBoundingClientRect() : null;
      if (rect) {
        if (rect.bottom + 250 < window.innerHeight) {
          // drop down
          setMenuPosition({
            top: rect.top + rect.height + window.scrollY,
            left: rect.left + window.scrollX,
          });
        } else {
          // drop up
          const numberOfSlots = 6;
          const estimatedMenuHeight =
            40 *
            (filteredOptions.length >= numberOfSlots ? numberOfSlots : filteredOptions.length + 1);
          setMenuPosition({
            top: rect.top - rect.height - estimatedMenuHeight + window.scrollY,
            left: rect.left + window.scrollX,
          });
        }
      }
    };

    document.getElementById('app-container')?.addEventListener('scroll', handleScroll);
    document
      .getElementById('scrollable-report-container')
      ?.addEventListener('scroll', handleScroll);

    return () => {
      document.getElementById('app-container')?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('scrollable-report-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, [filteredOptions.length]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    const rect = anchorRef.current?.getBoundingClientRect() ?? null;

    if (open && rect) {
      if (rect.bottom + 250 < window.innerHeight || filteredOptions.length === 0) {
        // drop down
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + window.scrollX,
        });
      } else {
        // drop up
        const numberOfSlots = 6;
        const estimatedMenuHeight =
          40 *
          (filteredOptions.length >= numberOfSlots ? numberOfSlots : filteredOptions.length + 1);
        setMenuPosition({
          top: rect.top - rect.height - estimatedMenuHeight + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    }
  }, [open, filteredOptions.length]);

  useEffect(() => {
    let filteredSelectedOptions = selectedValues.map((option) => ({
      ...option,
      isSelected: true,
    }));

    let filteredUnselectedOptions = options
      .filter((option) => {
        return !selectedValues.some((selectedOption) => selectedOption.label === option.label);
      })
      .map((option) => ({
        ...option,
        isSelected: false,
      }));

    if (searchValue) {
      filteredSelectedOptions = filteredSelectedOptions.filter((selectedOption) =>
        isPartialMatch(searchValue, selectedOption.label),
      );
      filteredUnselectedOptions = filteredUnselectedOptions.filter((option) =>
        isPartialMatch(searchValue, option.label),
      );
    }

    filteredSelectedOptions.sort(
      mapCompare<Option, string>((option) => option.label, compareAlphaNumeric.asc),
    );
    filteredUnselectedOptions.sort(
      mapCompare<Option, string>((option) => option.label, compareAlphaNumeric.asc),
    );
    let mappedSearchValue = [] as SelectableOption[];
    if (
      searchValue !== '' &&
      !filteredSelectedOptions.some((selectedOption) => selectedOption.label === searchValue) &&
      !filteredUnselectedOptions.some((selectedOption) => selectedOption.label === searchValue)
    ) {
      mappedSearchValue = [{ value: null, label: searchValue, isSelected: false }];
    }
    setFilteredOptions([
      ...filteredSelectedOptions,
      ...mappedSearchValue,
      ...filteredUnselectedOptions,
    ]);
  }, [searchValue, selectedValues, options]);

  const removeSelectedOption = (optionToRemove: Option) => {
    const currentOptions = selectedValues.filter((option) => option.label !== optionToRemove.label);
    onChange(currentOptions);
  };

  const removeChipOnClick = (event: React.MouseEvent, optionToRemove: Option) => {
    event.stopPropagation();
    removeSelectedOption(optionToRemove);
  };

  const addSelectedOption = (optionToAdd: Option) => {
    const currentOptions = [...selectedValues, optionToAdd];
    onChange(currentOptions);
  };

  const handleToggleDropdownOpen = () => {
    setOpen(!open);
  };

  const handleCreateNewOption = (option: string) => {
    addSelectedOption({ value: null, label: option });
    setSearchValue('');
    setOpen(false);
  };

  const handleClearAll = () => {
    onChange([]);
  };

  const componentWidth = anchorRef?.current?.getBoundingClientRect().width ?? 240;
  const dropdownWidth = componentWidth - (selectedValues.length > 2 ? 90 : 50); // width of dropdown - width of icons
  const chipWidth =
    Math.floor(dropdownWidth / (selectedValues.length >= 2 ? 2 : selectedValues.length)) -
    2 * (selectedValues.length >= 2 ? 2 : selectedValues.length);
  const labelWidth = chipWidth - 20;

  const dropdownMenu = menuPosition != null && (
    <div
      className="dropdown-menuList"
      ref={dropdownRef}
      style={{
        ...style,
        width: `${componentWidth}px`,
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
      }}
    >
      <InputWithIcon
        Icon={<SearchIcon />}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        inputProps={{
          placeholder: 'Search or Create New Tag',
        }}
        containerProps={{
          style: {
            margin: '0.5rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
        }}
      />

      {filteredOptions.map((option) => {
        return (
          <div
            key={option.value}
            className={option.isSelected ? 'dropdown-menuSelected' : 'dropdown-menuItem'}
            onClick={() => {
              if (!option.isSelected) {
                if (option.label === searchValue) {
                  handleCreateNewOption(option.label);
                } else {
                  addSelectedOption(option);
                }
              }
            }}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <span
              style={{
                paddingLeft: '1rem',
              }}
            >
              {option.label}
            </span>
            {option.label === searchValue && !option.isSelected && (
              <AddCircleOutline
                sx={{
                  fontSize: '1.2rem',
                  marginBottom: '3px',
                  marginLeft: '2px',
                  color: Theme.palette.primary.light,
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );

  const iconStyle = {
    width: '20px',
    height: '20px',
    color: Theme.palette.subHeading.main,
  };

  const arrow = open ? (
    <KeyboardArrowUp style={iconStyle} />
  ) : (
    <KeyboardArrowDown style={iconStyle} />
  );

  return (
    <>
      {dropdownHeader && (
        <div className="dropdown-header">
          <span style={{ color: 'black' }}>{dropdownHeader}</span>
        </div>
      )}
      <div
        className={`dropdown ${className}`}
        style={{
          border: '1px solid',
          borderColor: dropdownHeader ? Theme.palette.borderGrey.main : 'white',
        }}
        onClick={handleToggleDropdownOpen}
        ref={anchorRef}
      >
        <div className="dropdown-selectedValues">
          {selectedValues.length > 0 ? (
            selectedValues.slice(0, 2).map((selectedOption) => {
              return (
                <Chip
                  key={selectedOption.value}
                  label={selectedOption.label}
                  onClick={(event) => removeChipOnClick(event, selectedOption)}
                  style={{
                    borderColor: Theme.palette.borderGrey.main,
                    chipMaxWidth: `${chipWidth}px`,
                    labelMaxWidth: `${labelWidth}px`,
                  }}
                />
              );
            })
          ) : (
            <div className="dropdown-selectedValues" style={{ height: '30px' }} />
          )}
          {selectedValues.length > 2 && (
            <Chip
              key={0}
              label={`+${selectedValues.length - 2}`}
              onClick={handleToggleDropdownOpen}
              style={{
                borderColor: Theme.palette.borderGrey.main,
                chipMaxWidth: '35px',
                labelMaxWidth: '35px',
              }}
              chipIcon={<div />}
            />
          )}
        </div>
        <div className="dropdown-icons">
          {selectedValues.length > 0 && (
            <CloseIcon size="15px" color={Theme.palette.subHeading.main} onClick={handleClearAll} />
          )}
          {arrow}
        </div>
      </div>
      {open && ReactDOM.createPortal(dropdownMenu, document.body)}
    </>
  );
}

function isPartialMatch(query: string, target: string): boolean {
  return target?.toLowerCase().includes(query.toLowerCase());
}
