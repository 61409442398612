import {
  GetTimelineEntriesQueryVariables,
  PageWhereInput,
  SortOrder,
  TimelineEntryOrderByInput,
  TagType,
  PageObject,
} from '../../../../__generated__/graphql';
import convertEnumToFormattedString from '../../../../library/utilities/convertEnumToFormattedString';
import { UserIdHost } from '../../types/commonTypes';
import {
  KeywordSearchConstraints,
  KeywordSearchFilteredDocuments,
  ListPagesSortByEnum,
  Page,
  TimelineFilters,
  UseTimelineDocumentsParams,
} from '../../types/timelineTypes';

export const createOrderByFromFilters = (
  filters: Pick<TimelineFilters, 'documentOrder'> | undefined,
): TimelineEntryOrderByInput => {
  switch (filters?.documentOrder) {
    case ListPagesSortByEnum.DateAscending:
      return {
        page_date: SortOrder.Asc,
      };
    case ListPagesSortByEnum.DateDescending:
      return {
        page_date: SortOrder.Desc,
      };
    case ListPagesSortByEnum.Document:
      return {
        document: SortOrder.Asc,
      };
    default:
      console.warn(`Unknown document order: ${filters?.documentOrder}`);
      return {
        page_date: SortOrder.Desc,
      };
  }
};

const mergeFilters = (...filters: Record<string, any>[]) => {
  let result: Record<string, any> = {};

  filters.forEach((filter) => {
    const { OR, AND, ...restFilters } = filter;

    if ('OR' in result || OR) {
      result = {
        ...result,
        OR: [...(result.OR ?? []), ...(OR ?? [])],
      };
    }

    if ('AND' in result || AND) {
      result = {
        ...result,
        AND: [...(result.AND ?? []), ...(AND ?? [])],
      };
    }

    result = { ...result, ...restFilters };
  });

  return result;
};

export const createPageWhereInputFromFilters = (
  filters: TimelineFilters | undefined,
  userId: string,
): PageWhereInput => {
  const favouriteFilter: Pick<PageWhereInput, 'is_favourite'> = filters?.showFavourited
    ? { is_favourite: { equals: 1 } }
    : {};

  const pagesAfterFilter: PageWhereInput = filters?.afterDate
    ? {
        OR: [
          {
            corrected_page_date: {
              gte: new Date(filters?.afterDate),
            },
          },
          {
            corrected_page_date: null,
            page_date: {
              gte: new Date(filters?.afterDate),
            },
          },
        ],
      }
    : {};

  const pagesBeforeFilter: PageWhereInput = filters?.beforeDate
    ? {
        OR: [
          {
            corrected_page_date: {
              lte: new Date(filters?.beforeDate),
            },
          },
          {
            corrected_page_date: null,
            page_date: {
              lte: new Date(filters?.beforeDate),
            },
          },
        ],
      }
    : {};

  const pagesViewedFilter: Pick<PageWhereInput, 'pages_viewed'> = filters?.hideViewed
    ? {
        pages_viewed: {
          every: {
            user_id: {
              not: {
                equals: userId,
              },
            },
          },
        },
      }
    : {};

  const duplicatesFilter:
    | { OR: Array<Pick<PageWhereInput, 'is_duplicate'>> }
    | Pick<PageWhereInput, 'is_duplicate'> = filters?.hideDuplicates
    ? {
        OR: [
          {
            is_duplicate: {
              equals: 0,
            },
          },
          {
            is_duplicate: {
              equals: null,
            },
          },
        ],
      }
    : {
        OR: [
          {
            is_duplicate: {
              equals: 1,
            },
          },
          {
            is_duplicate: {
              equals: 0,
            },
          },
          {
            is_duplicate: {
              equals: null,
            },
          },
        ],
      };

  const showPagesWithNoContentTags =
    filters?.contentTypes?.map((option) => option?.id).includes(-1) ?? false;
  const sourceIds =
    filters?.sources?.map((source) => source?.id ?? null).filter((id) => id !== null) ?? [];

  const contentIds = [
    ...(filters?.contentTypes ?? []),
    ...(filters?.subContentTypes ?? []),
    ...(filters?.specialities ?? []),
  ]
    .map((content) => content?.id)
    .filter((id) => id !== undefined);

  const pageByTagsFilter: PageWhereInput = isTagFilterApplied(filters)
    ? {
        AND: [
          {
            OR: [
              showPagesWithNoContentTags
                ? {
                    page_to_tag: {
                      none: {
                        dismissed: { equals: false },

                        tags: {
                          is: {
                            type: {
                              in: [TagType.ContentType, TagType.Speciality],
                            },
                          },
                        },
                      },
                      some: {
                        dismissed: { equals: false },
                        tags: {
                          is: {
                            id: {
                              in: sourceIds,
                            },
                          },
                        },
                      },
                    },
                  }
                : {},

              {
                AND: [
                  {
                    page_to_tag: {
                      some: {
                        dismissed: { equals: false },
                        tags: {
                          is: {
                            id: {
                              in: sourceIds,
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    page_to_tag: {
                      some: {
                        dismissed: { equals: false },
                        tags: {
                          is: {
                            id: {
                              in: contentIds,
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }
    : {};

  return mergeFilters(
    favouriteFilter,
    pagesViewedFilter,
    {
      AND: [pagesBeforeFilter, pagesAfterFilter],
    },
    duplicatesFilter,
    pageByTagsFilter,
  );
};

export const createKeywordSearchFilter = (constraints: KeywordSearchConstraints | undefined) => {
  const normalizedKeywordSearchConstraints = Object.fromEntries(
    Object.entries(constraints ?? {}).filter(
      ([documentId]) =>
        documentId !== 'totalOccurancesFound' &&
        documentId !== 'totalCount' &&
        documentId !== 'totalIncludingHiddenDocumentsAndPages',
    ),
  ) as KeywordSearchFilteredDocuments;

  const matches = Object.entries(normalizedKeywordSearchConstraints)
    .map(([documentId, pages]) =>
      Object.keys(pages)
        .filter((pageNumber) => pageNumber !== 'numOfMatchesInDocument')
        .map((pageNumber) => ({
          page_number: parseInt(pageNumber, 10),
          document_id: documentId,
        })),
    )
    .flat();

  return {
    total_results: constraints?.totalOccurancesFound ?? (0 as number),
    matches,
  };
};

export const combinePageWhereInputWithKeywordSearchResult = (
  pageWhereInput: PageWhereInput,
  keywordSearchFilter: ReturnType<typeof createKeywordSearchFilter>,
): PageWhereInput => {
  if (keywordSearchFilter.matches.length === 0) {
    return {
      AND: [...[]],
    };
  }
  return {
    AND: [
      {
        OR: keywordSearchFilter.matches.map((match) => ({
          page_number: { equals: match.page_number },
        })),
      },
    ],
  };
};

export const processorKeywordSearchFilter = (
  keywordSearchFilter: ReturnType<typeof createKeywordSearchFilter>,
) => {
  if (keywordSearchFilter.matches.length === 0) {
    return {
      AND: [...[]],
    };
  }
  return {
    AND: [
      {
        OR: keywordSearchFilter.matches.map((match) => ({
          page_number: { equals: match.page_number },
        })),
      },
    ],
  };
};

export const isTagFilterApplied = (filters: TimelineFilters | undefined): boolean =>
  Boolean(
    filters?.sources || filters?.contentTypes || filters?.subContentTypes || filters?.specialities,
  );

export const createAllowedTagIdsFromFilters = (filters: TimelineFilters | undefined): number[] => {
  return [...(filters?.contentTypes ?? []), ...(filters?.subContentTypes ?? [])].map(
    ({ tag_id }) => tag_id,
  );
};

export const createGetTimelineDocumentsInputFromFilters = (
  params: UseTimelineDocumentsParams & UserIdHost,
  isFileProcessor: boolean,
): GetTimelineEntriesQueryVariables => {
  const pageWhereInput = createPageWhereInputFromFilters(params.filters, params.userId);
  const allowedSources = params.filters?.sources?.map((item) => item.tag_id) ?? null;

  const allowedTagIds = isTagFilterApplied(params.filters)
    ? createAllowedTagIdsFromFilters(params.filters)
    : null;

  const keywordSearchFilter = createKeywordSearchFilter(params.keywordSearchConstraints);

  const preparedKeywordSearchFilter: PageWhereInput = combinePageWhereInputWithKeywordSearchResult(
    pageWhereInput,
    keywordSearchFilter,
  );

  if (isFileProcessor) {
    return {
      where: {
        case_id: params.caseId,
        hideDuplicates: false,
        hideViewed: false,
        keyword_search_result: {
          keyword: params.searchKeyword ?? '',
          total_results: keywordSearchFilter.total_results as number,
          matches: keywordSearchFilter.matches,
        },
      },
      pagesWhere: preparedKeywordSearchFilter,
      orderBy: createOrderByFromFilters(params.filters),
    };
  }

  return {
    where: {
      case_id: params.caseId,
      showFavourited: params.filters?.showFavourited,
      hideViewed: params.filters?.hideViewed,
      hideDuplicates: params.filters?.hideDuplicates,
      document_id: params.filters.documentID ?? null,
      allowedSourceTags: allowedSources,
      allowedDocumentTags: allowedTagIds,
      beforeDate: params.filters?.beforeDate ?? null,
      afterDate: params.filters?.afterDate ?? null,
      keyword_search_result: {
        keyword: params.searchKeyword ?? '',
        total_results: keywordSearchFilter.total_results as number,
        matches: keywordSearchFilter.matches,
      },
    },
    orderBy: createOrderByFromFilters(params.filters),
    pagesWhere: preparedKeywordSearchFilter,
  };
};

const getAllCommonContentTypes = (allPages: PageObject[]) => {
  if (!allPages || allPages.length === 0) {
    return [];
  }

  let commonContentTypes = allPages[0].tags;

  allPages.forEach((page) => {
    commonContentTypes = commonContentTypes.filter((type) => page.tags.includes(type));
  });

  return commonContentTypes;
};
