/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, Box, Typography, Link, Button } from '@mui/material';
import { Close, ArrowCircleRightOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import SiftySideDesign from '../icons/SiftySideDesign';

/**
 * Announcement
 */

export default function Announcement({
  announcementTitle,
  open,
  onClose,
  announcementBody,
  link,
  ...props
}) {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: !open ? 'none' : 'flex',
        background: 'linear-gradient(90.03deg, #00F3B1 0.01%, #56F6C4 24.48%, #D1FADF 99.97%)',
        width: { sm: '45rem', md: '55rem', lg: '72rem', xl: '77rem' },
        borderRadius: 3,
        marginTop: '1.5rem',
        flexDirection: 'row',
      }}
      {...props}
    >
      <Box
        sx={{
          width: '15%',
          height: '100%',
          overflow: 'hidden',
          display: 'inline',
          borderRadius: 3,
        }}
      >
        <SiftySideDesign />
      </Box>

      <Box
        sx={{
          textAlign: 'left',
          overflow: 'hidden',
          paddingTop: '1.2rem',
          width: '84%',
          paddingBottom: '1rem',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '1rem',
        }}
      >
        <Box sx={{ cursor: 'pointer' }}>
          <Typography
            sx={{
              justifySelf: 'left',
              ml: '0.8rem',
              fontWeight: 400,
              fontSize: {
                xs: '0.7rem',
                sm: '0.7rem',
                md: '0.7rem',
                lg: '0.75rem',
                xl: '0.75rem',
              },
            }}
          >
            {announcementTitle}
          </Typography>
          <Typography
            variant="body3"
            sx={{
              ml: '0.8rem',
              mt: '10px',
              display: {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
              maxWidth: '96%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: {
                xs: '0.75rem',
                sm: '0.75rem',
                md: '0.75rem',
                lg: '0.85rem',
                xl: '0.85rem',
              },
            }}
          >
            {announcementBody}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
          {link && (
            <Link
              sx={{
                textDecoration: 'none',
                color: '#09152C',
                '&:hover': { textDecoration: 'none', color: '#09152C' },
              }}
              href={link}
              target="_blank"
            >
              <Button
                variant="contained"
                sx={{ fontSize: '0.8rem' }}
                endIcon={<ArrowCircleRightOutlined sx={{ fontSize: '1.3rem', ml: '0.2rem' }} />}
              >
                Learn More
              </Button>
            </Link>
          )}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              fontSize: '1.6rem',
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

Announcement.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  announcementTitle: PropTypes.string,
  announcementBody: PropTypes.string,
};

Announcement.defaultProps = {
  open: true,
  onClose: undefined,
  announcementTitle: '',
  announcementBody: '',
};
