import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';

export default function EditHoverCellEffect({
  cellValue,
  formatValueFunction,
  displayIcon,
  isEditable = true,
  params,
  multiLine = false,
}: {
  cellValue: any;
  formatValueFunction?: (value: any) => string | number;
  displayIcon?: any;
  isEditable: boolean;
  params: GridRenderCellParams;
  multiLine?: boolean;
}) {
  const [hover, setHover] = useState(false);
  const handleEditClick = () => {
    params.api.startCellEditMode({
      id: params.id,
      field: params.field,
    });
  };
  const displayValue = formatValueFunction ? formatValueFunction(cellValue) : cellValue ?? '';

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          overflow: multiLine ? '' : 'hidden',
          textOverflow: multiLine ? '' : 'ellipsis',
          whiteSpace: multiLine ? 'wrap' : 'nowrap',
        }}
      >
        {displayIcon != null && <span style={{ marginRight: '0.25rem' }}>{displayIcon}</span>}
        {displayValue}
      </Box>
      {isEditable && hover && (
        <Edit
          style={{
            flex: '0 0 auto',
            marginLeft: '1rem',
            bottom: '2px',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={handleEditClick}
        />
      )}
    </Box>
  );
}
