import { useParams } from 'react-router-dom';
import { useApolloClient, gql } from '@apollo/client';
import { useUpdatePageHidden } from '../../../containers/Page/gql/useUpdatePageHidden';
import NavigationButton from '../../common/PdfNavigation/Components/NavigationButton';
import IconVisible from '../../icons/IconVisible';
import IconHidden from '../../icons/IconHidden';
import Theme from '../../../theme';

export function PageControls() {
  const { pageID } = useParams();
  const apolloClient = useApolloClient();

  const currentPage = apolloClient.readFragment({
    id: apolloClient.cache.identify({
      id: pageID,
      __typename: 'PageObject',
    }),
    fragment: gql`
      fragment CurrentPageHiddenStatus on PageObject {
        id
        is_hidden
      }
    `,
  });

  const HideIconComponent = currentPage?.is_hidden ? IconHidden : IconVisible;
  const { mutateAsync: hidePageMutation } = useUpdatePageHidden();
  const handleHidePage = () => {
    if (currentPage?.id) {
      hidePageMutation({ pageID: currentPage.id, hidden: !currentPage?.is_hidden });
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {currentPage?.id && (
        <div className="viewer-buttons-container-rotations">
          <NavigationButton
            onClick={handleHidePage}
            sx={{
              mr: 0.5,
              opacity: '100%',
              '&:hover': {
                backgroundColor: currentPage?.is_hidden ? '#E5EDF5' : 'white',
                boxShadow: 'none',
              },
            }}
            icon={
              <HideIconComponent
                style={{
                  cursor: 'pointer',
                  color: currentPage?.is_hidden
                    ? Theme.palette.primary.light
                    : Theme.palette.selectedGrey.contrastText,
                }}
              />
            }
          />
        </div>
      )}
    </div>
  );
}
