import React, { useState, useMemo } from 'react';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import NoteEditableTemplate from './NoteEditableTemplate';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import useUpdateNote from './gql/useUpdateNote';
import { useGetPageByIdQuery } from '../../__generated__/graphql';
import useSources from '../Timeline/gql/useSources';

const useStore = createSelectorFunctions(useScreenCaptureStore);

function EditNote({ open = false, noteBeingEdited = {}, onClose, onSuccess }) {
  const [updateNote] = useUpdateNote();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [imagesToRemove, setImagesToRemove] = useState([]);

  const screenCaptures = useStore.use.screenCaptures();
  const setScreenCaptures = useStore.use.setScreenCaptures();

  async function submitUpdateNote(title, body, physician, tags, date, privateNote) {
    setIsSubmitting(true);

    const note = {
      ...noteBeingEdited,
      title: title,
      body: body,
      physician: physician,
      tags: tags,
      privateNote: +privateNote,
      tagsToAdd: tags
        .filter((t) => !noteBeingEdited.tags.find((n) => n.tagID === t.tagID))
        .map((n) => n.tagID),
      tagsToRemove: noteBeingEdited.tags
        .filter((tag) => !tags.find((t) => t.tagID === tag.tagID))
        ?.map((t) => t.tagID),
      date: date,
      imagesToRemove,
      imagesToAdd: screenCaptures,
    };

    try {
      await updateNote({
        variables: {
          note_id: note.noteID,
          editNoteData: note,
        },
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
    onClose();
  }

  const onDeleteImage = (imageToRemove) => {
    if (screenCaptures.includes(imageToRemove)) {
      setScreenCaptures(screenCaptures.filter((capture) => capture !== imageToRemove));
    } else {
      noteBeingEdited.images = noteBeingEdited.images.filter(
        (image) => image.imageID !== imageToRemove.imageID,
      );
      if (imageToRemove.imageID) {
        setImagesToRemove([...imagesToRemove, imageToRemove.imageID]);
      }
    }
  };

  const maxCaptures = screenCaptures.length + (noteBeingEdited?.images?.length || 0) >= 9;

  const [partialNote, setPartialNote] = useState(null);
  const onTakeScreenshotClick = (note) => setPartialNote(note);

  const { data: pageObject } = useGetPageByIdQuery({
    variables: {
      id: noteBeingEdited.pageID,
    },
    skip: !noteBeingEdited.pageID,
  });

  const sources = useSources();

  const pageSource = useMemo(
    () => sources?.find((source) => source.tag_id === pageObject?.page?.source_id)?.name,
    [pageObject, sources],
  );

  const notePage = {
    documentID: noteBeingEdited.documentID,
    pageDate: noteBeingEdited.date,
    pageNumber: noteBeingEdited.pageNumber,
    pageID: noteBeingEdited.pageID,
    source: pageSource ?? '',
  };

  return (
    <NoteEditableTemplate
      open={open}
      editNote={true}
      defaultNoteValues={
        partialNote ? { ...partialNote, images: noteBeingEdited.images } : noteBeingEdited
      }
      onClose={onClose}
      onDeleteImage={onDeleteImage}
      onTakeScreenshotClick={onTakeScreenshotClick}
      isSubmitting={isSubmitting}
      onSubmit={submitUpdateNote}
      page={notePage}
    />
  );
}

export default EditNote;
