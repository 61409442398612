/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */
import { DocumentEdge, PageObject } from '../../../api';

abstract class Node<TNode extends Node<any>> {
  parent: ParentNode<TNode> | null = null;

  previousSibling: TNode | null = null;

  nextSibling: TNode | null = null;

  abstract clone(): TNode | ParentNode<TNode>;

  depth(): number {
    let depth = 0;
    let current: Node<any> | null = this;
    while (current) {
      depth += 1;
      current = current.parent;
    }
    return depth;
  }

  remove() {
    // link the previous and next siblings
    if (this.previousSibling) {
      this.previousSibling.nextSibling = this.nextSibling;
    }
    if (this.nextSibling) {
      this.nextSibling.previousSibling = this.previousSibling;
    }

    // update the parent's first and last children
    const parent = this.parent;
    if (parent) {
      if ((parent.firstChild as any) === this) {
        parent.firstChild = this.nextSibling;
      }
      if ((parent.lastChild as any) === this) {
        parent.lastChild = this.previousSibling;
      }
    }

    // remove all references
    this.parent = null;
    this.nextSibling = null;
    this.previousSibling = null;
  }

  insertAfter(node: TNode): void {
    // remove itself form current location
    this.remove();

    // update new parent child relationships
    const newParent = node.parent;
    if (newParent) {
      this.parent = newParent;

      if (newParent.lastChild === node) {
        newParent.lastChild = this;
      }
    }

    // update new sibling relationships
    if (node.nextSibling) {
      this.nextSibling = node.nextSibling;
      node.nextSibling.previousSibling = this;
    }
    node.nextSibling = this;
    this.previousSibling = node;
  }

  insertBefore(node: TNode): void {
    // remove itself form current location
    this.remove();

    // update new parent child relationships
    const newParent = node.parent;
    if (newParent) {
      this.parent = newParent;

      if (newParent.firstChild === node) {
        newParent.firstChild = this;
      }
    }

    // update new sibling relationships
    if (node.previousSibling) {
      this.previousSibling = node.previousSibling;
      node.previousSibling.nextSibling = this;
    }
    node.previousSibling = this;
    this.nextSibling = node;
  }

  *previousSiblings(): Generator<TNode, void, void> {
    let current: TNode | null = this.previousSibling;
    while (current) {
      yield current;
      current = current.previousSibling;
    }
  }

  *nextSiblings(): Generator<TNode, void, void> {
    let current: TNode | null = this.nextSibling;
    while (current) {
      yield current;
      current = current.nextSibling;
    }
  }
}

class ParentNode<Child extends Node<any>> extends Node<Child> {
  firstChild: Child | null = null;

  lastChild: Child | null = null;

  maxDepth(flags = { depth: 0 }): number {
    flags.depth = Math.max(flags.depth, this.depth());

    for (const child of this.children()) {
      if (child instanceof ParentNode) {
        child.maxDepth(flags);
      }
    }

    return flags.depth;
  }

  *children(): Generator<Child, void, void> {
    let current: Child | null = this.firstChild;
    while (current) {
      yield current;
      current = current.nextSibling;
    }
  }

  *childrenReverse(): Generator<Child, void, void> {
    let current: Child | null = this.lastChild;
    while (current) {
      yield current;
      current = current.previousSibling;
    }
  }

  *ancestors(): Generator<ParentNode<Child>, void, void> {
    let current: ParentNode<Child> | null = this.parent;
    while (current) {
      yield current;
      current = current.parent;
    }
  }

  *descendants(): Generator<Child, void, void> {
    for (const child of this.children()) {
      yield child;
      if (child instanceof ParentNode) {
        yield* child.descendants();
      }
    }
  }

  clone(): ParentNode<Child> {
    const clone = new (this.constructor as new () => ParentNode<Child>)();
    for (const child of this.children()) {
      clone.appendChild(child.clone());
    }
    return clone;
  }

  hasChild(node: Child): boolean {
    for (const child of this.children()) {
      if (child === node) {
        return true;
      }
    }
    return false;
  }

  hasDescendant(node: Child): boolean {
    for (const child of this.children()) {
      if (child === node) {
        return true;
      }
      if (child instanceof ParentNode && child.hasDescendant(node)) {
        return true;
      }
    }
    return false;
  }

  appendChild(node: Child): void {
    if (this.lastChild) {
      node.insertAfter(this.lastChild);
      return;
    }

    node.remove();
    node.parent = this;
    this.firstChild = node;
    this.lastChild = node;
  }

  prependChild(node: Child): void {
    if (this.firstChild) {
      node.insertBefore(this.firstChild);
      return;
    }

    node.remove();
    node.parent = this;
    this.firstChild = node;
    this.lastChild = node;
  }
}

class DocumentNode extends ParentNode<DocumentNode | PageNode> {
  document_id?: string | null;

  constructor(document_id?: string | null) {
    super();
    this.document_id = document_id;
  }

  *allPages(flags = { index: 0 }): Generator<[PageObject, number], void, void> {
    for (const child of this.children()) {
      if (child instanceof PageNode) {
        yield [child.page, flags.index];
      } else if (child instanceof DocumentNode) {
        yield* child.allPages(flags);
      }
      flags.index++;
    }
  }

  *allDocuments(): Generator<DocumentNode, void, void> {
    for (const child of this.children()) {
      if (child instanceof DocumentNode) {
        yield child;
        yield* child.allDocuments();
      }
    }
  }

  *allEdges(): Generator<
    {
      type: DocumentEdge['type'];
      page_id: PageObject['id'];
      page_number: PageObject['page_number'];
    },
    void,
    void
  > {
    const firstPage = this.startEdgePage();
    if (firstPage && this.parent) {
      yield { type: 'start', page_id: firstPage.page.id, page_number: firstPage.page.page_number };
    }
    for (const child of this.children()) {
      if (child instanceof DocumentNode) {
        yield* child.allEdges();
      }
    }
    const lastPage = this.endEdgePage();
    if (lastPage && this.parent) {
      yield { type: 'end', page_id: lastPage.page.id, page_number: lastPage.page.page_number };
    }
  }

  *pages(): Generator<PageNode, void, void> {
    for (const child of this.children()) {
      if (child instanceof PageNode) {
        yield child;
      }
    }
  }

  *documents(): Generator<DocumentNode, void, void> {
    for (const child of this.children()) {
      if (child instanceof DocumentNode) {
        yield child;
      }
    }
  }

  firstPage(): PageNode | null {
    for (const child of this.children()) {
      if (child instanceof PageNode) {
        return child;
      }
    }
    return null;
  }

  lastPage(): PageNode | null {
    for (const child of this.childrenReverse()) {
      if (child instanceof PageNode) {
        return child;
      }
    }
    return null;
  }

  startEdgePage(): PageNode | null {
    let firstChild = this.firstChild;
    while (firstChild instanceof DocumentNode) {
      firstChild = firstChild.firstChild;
    }

    return firstChild ?? null;
  }

  endEdgePage(): PageNode | null {
    let lastChild = this.lastChild;
    while (lastChild instanceof DocumentNode) {
      lastChild = lastChild.lastChild;
    }

    return lastChild ?? null;
  }

  findPage(pageId: PageObject['id']): PageNode | null {
    for (const child of this.children()) {
      if (child instanceof PageNode && child.page.id === pageId) {
        return child;
      }
      if (child instanceof DocumentNode) {
        const page = child.findPage(pageId);
        if (page) {
          return page;
        }
      }
    }
    return null;
  }

  findDocument(pageId: PageObject['id']): DocumentNode | null {
    const firstChild = this.firstChild;
    if (firstChild instanceof PageNode && firstChild.page.id === pageId) {
      return this;
    }
    for (const child of this.children()) {
      if (child instanceof DocumentNode) {
        const document = child.findDocument(pageId);
        if (document) {
          return document;
        }
      }
    }
    return null;
  }

  depthAt(pageId: PageObject['id']): number {
    const page = this.findPage(pageId);
    if (!page) {
      return 0;
    }
    return page.depth();
  }

  canSplitAt(pageId: PageObject['id']): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    if (!page.parent) {
      return false;
    }

    if (!page.previousSibling) {
      return false;
    }

    return true;
  }

  splitAt(pageId: PageObject['id']): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }
    const previousSibling = page.previousSibling;
    const parent = page.parent as DocumentNode | null;
    if (!parent) {
      return false;
    }

    if (!previousSibling) {
      return false;
    }

    const newDocument = new DocumentNode();

    let current = page;
    while (current) {
      const next = current.nextSibling;
      newDocument.appendChild(current);
      current = next;
    }

    newDocument.insertAfter(parent);

    return true;
  }

  canMergeAt(pageId: PageObject['id']): boolean {
    const document = this.findDocument(pageId);

    if (!document) {
      return false;
    }

    const previousSibling = document.previousSibling;
    if (!previousSibling) {
      return false;
    }

    if (!(previousSibling instanceof DocumentNode)) {
      return false;
    }

    return true;
  }

  mergeAt(pageId: PageObject['id']): boolean {
    const document = this.findDocument(pageId);

    if (!document) {
      return false;
    }

    const previousSibling = document.previousSibling;
    if (!previousSibling) {
      return false;
    }

    if (!(previousSibling instanceof DocumentNode)) {
      return false;
    }

    let current = document.firstChild;
    while (current) {
      const next = current.nextSibling;
      previousSibling.appendChild(current);
      current = next;
    }

    document.remove();

    return true;
  }

  canAttachAt(pageId: PageObject['id'], depth = 4): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    return page.depth() < depth;
  }

  attachAt(pageId: PageObject['id'], depth = 4): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    if (page.depth() >= depth) {
      return false;
    }

    const parent = page.parent as DocumentNode;
    const previousSibling = page.previousSibling;

    if (previousSibling) {
      if (previousSibling instanceof DocumentNode) {
        previousSibling.appendChild(page);
      } else {
        const document = new DocumentNode();
        document.appendChild(page);
        document.insertAfter(previousSibling);
      }
    } else {
      const document = new DocumentNode();
      document.appendChild(page);
      parent.prependChild(document);
    }

    return true;
  }

  startAttachmentAt(pageId: PageObject['id'], depth = 4): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    if (page.depth() >= depth) {
      return false;
    }

    const parent = page.parent as DocumentNode;
    const previousSibling = page.previousSibling;

    const document = new DocumentNode();
    let current = page;
    while (current instanceof PageNode) {
      const next = current.nextSibling;
      document.appendChild(current);
      current = next;
    }

    if (previousSibling) {
      document.insertAfter(previousSibling);
    } else {
      parent.prependChild(document);
    }

    return true;
  }

  canDetachAt(pageId: PageObject['id']): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    return page.depth() > 3;
  }

  detachAt(pageId: PageObject['id']): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    if (page.depth() <= 3) {
      return false;
    }

    const attachment = page.parent as DocumentNode;

    if (attachment.firstChild === page) {
      page.insertBefore(attachment);
    } else if (attachment.lastChild === page) {
      page.insertAfter(attachment);
    } else {
      this.splitAt(pageId);
      page.insertAfter(attachment);
    }

    if (!attachment.firstChild) {
      attachment.remove();
    }

    return true;
  }

  endAttachmentAt(pageId: PageObject['id']): boolean {
    const page = this.findPage(pageId);
    if (!page) {
      return false;
    }

    if (page.depth() <= 3) {
      return false;
    }

    const attachment = page.parent as DocumentNode;

    let previous = attachment as DocumentNode | PageNode;
    let current = page;
    while (current) {
      const next = current.nextSibling;
      current.insertAfter(previous);
      previous = current;
      current = next;
    }

    if (!attachment.firstChild) {
      attachment.remove();
    }

    return true;
  }

  // todo implement regrouping over a range of pages
  // todo reordering of pages/documents

  toJSON(): any {
    return {
      type: 'Document',
      document_id: this.document_id,
      children: [...this.children()].map((child) => child.toJSON()),
    };
  }

  print(depth = 0): string {
    const indent = '  '.repeat(depth);
    const name = this.parent
      ? this.document_id != null
        ? `Document(${this.document_id})`
        : 'Document'
      : 'File';
    return `${indent}${name}\n${[...this.children()]
      .map((child) => child.print(depth + 1))
      .join('\n')}`;
  }

  printEdges(): string {
    return [...this.allEdges()].map((edge) => `${edge.type}(${edge.page_id})`).join('\n');
  }

  static fromPages(pages: PageObject[]): Result<DocumentNode, EdgeError> {
    const root = new DocumentNode();
    let current = root;

    if (pages.length === 0) {
      return { type: 'ok', value: root };
    }

    const hasFirstStartEdge = pages[0]!.document_edges.some((edge) => edge.type === 'start');

    if (!hasFirstStartEdge) {
      return { type: 'error', error: { type: 'FirstPageMustHaveStartEdge', document: current } };
    }

    const hasLastEndEdge = pages[pages.length - 1]!.document_edges.some(
      (edge) => edge.type === 'end',
    );

    if (!hasLastEndEdge) {
      return { type: 'error', error: { type: 'LastPageMustHaveEndEdge', document: current } };
    }
    for (const page of pages) {
      const startEdges = countWhere(page.document_edges, (edge) => edge.type === 'start');

      for (let i = 0; i < startEdges; i += 1) {
        const document = new DocumentNode();
        current.appendChild(document);
        current = document;
      }

      current.appendChild(new PageNode(page));

      const endEdges = countWhere(page.document_edges, (edge) => edge.type === 'end');

      for (let i = 0; i < endEdges; i += 1) {
        if (!current.parent) {
          return { type: 'error', error: { type: 'UnexpectedEndEdge', document: current } };
        }

        current = current.parent as DocumentNode;
      }
    }

    if (current !== root) {
      return {
        type: 'error',
        error: { type: 'UnexpectedEndOfInput', document: current },
      };
    }

    return { type: 'ok', value: root };
  }

  static fromJSON(json: any): DocumentNode {
    const root = new DocumentNode(json.document_id);
    for (const child of json.children) {
      if (child.type === 'Document') {
        const document = DocumentNode.fromJSON(child);
        root.appendChild(document);
      } else if (child.type === 'Page') {
        const page = new PageNode(child);
        root.appendChild(page);
      }
    }
    return root;
  }

  static diffDocuments(
    current: DocumentNode,
    next: DocumentNode,
  ): {
    update: DocumentNode[];
    insert: DocumentNode[];
    remove: DocumentNode[];
  } {
    const currentDocumentsByFirstPageID = new Map<string, DocumentNode>();
    for (const document of current.allDocuments()) {
      const firstPage = document.firstPage();
      if (firstPage) {
        currentDocumentsByFirstPageID.set(firstPage.page.id, document);
      }
    }

    const update: DocumentNode[] = [];
    const insert: DocumentNode[] = [];
    const remove: DocumentNode[] = [];

    const nextDocumentsByFirstPageID = new Map<string, DocumentNode>();
    for (const document of next.allDocuments()) {
      const firstPage = document.firstPage();
      if (firstPage) {
        nextDocumentsByFirstPageID.set(firstPage.page.id, document);
        const currentDocument = currentDocumentsByFirstPageID.get(firstPage.page.id);

        if (currentDocument) {
          document.document_id = currentDocument.document_id;
          update.push(document);
        } else {
          insert.push(document);
        }
      }
    }

    for (const document of current.allDocuments()) {
      const firstPage = document.firstPage();
      if (firstPage && !nextDocumentsByFirstPageID.has(firstPage.page.id)) {
        remove.push(document);
      }
    }

    return { update, insert, remove };
  }
}

class PageNode extends Node<any> {
  page: PageObject;

  constructor(page: PageObject) {
    super();
    this.page = page;
  }

  clone(): PageNode {
    return new PageNode(this.page);
  }

  toJSON(): any {
    return {
      type: 'Page',
      page_id: this.page.id,
      page_number: this.page.page_number,
    };
  }

  print(depth: number): string {
    const indent = '  '.repeat(depth);
    return `${indent}Page(${this.page.id})`;
  }
}
export type { PageNode, DocumentNode };
export type Result<T, E> = { type: 'ok'; value: T } | { type: 'error'; error: E };

export type EdgeError = { type: string; document: DocumentNode };

export function countWhere<T>(array: T[], predicate: (item: T) => boolean): number {
  let count = 0;
  for (const item of array) {
    if (predicate(item)) {
      count += 1;
    }
  }
  return count;
}

export function parsePages(pages: PageObject[]): Result<DocumentNode, EdgeError> {
  return DocumentNode.fromPages(pages);
}
