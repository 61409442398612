import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Tooltip, Chip, Switch } from '@mui/material';

import './document-grouping-container.css';
import NavBar from '../../components/common/PdfNavigation/NavBar';
import DocumentScroller from '../Case/DocumentScroller';
import Loading from '../../components/common/Loading';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import CaseHeader from '../Timeline/CaseHeader';
import { getCaseFile, PageObject } from '../../api';
import { useAsync } from '../../hooks/useAsync';
import UpArrowWithTail from '../../components/icons/UpArrowWithTail';
import { useGroupings } from './utils/documentState';
import Modal from '../../components/common/HTML_components/Modal/Modal';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import Theme from '../../theme';
import Attachment from '../../components/icons/Attachment';
import CheckMark from '../../components/icons/CheckMark';
import GroupingPageRange from './GroupingPageRange';

export default function DocumentGroupingContainer() {
  const { fileID: caseFileID, pageID, caseID } = useParams();
  const logUserActivity = useActivityLog();
  const [caseFile, refetchCaseFile] = useAsync(
    () => getCaseFile(caseID ?? '', caseFileID ?? ''),
    [caseID, caseFileID],
  );

  const { caseInstance } = useContext(CaseContext);
  const { data: timelineList } = useTimelineList(caseID);

  const [isMergeAllModalOpen, setIsMergeAllModalOpen] = useState(false);
  const [isBuildGroupingsModalOpen, setIsBuildGroupingsModalOpen] = useState(false);
  const navigate = useNavigate();

  const qAorProcessor: 'qa' | 'mdoc' | undefined = useMemo(() => {
    if (caseFile.status !== 'resolved') {
      return undefined;
    }
    if (
      caseFile.data.data?.file_status === 'QA_REQUIRED' ||
      caseFile.data.data?.file_status === 'APPROVED'
    ) {
      return 'qa';
    }
    return 'mdoc';
  }, [caseFile]);

  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const {
    status,
    pages,
    refreshPages,
    parseError,
    canSplitAt,
    splitAt,
    canMergeAt,
    mergeAt,
    canAttachAt,
    attachAt,
    canDetachAt,
    detachAt,
    depthAt,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    buildGroupings,
    resetEdges,
    canBuildGroupings,
    groupedDocumentTree,
    startPage,
    endPage,
    onChangePageRange,
    documentScrollerCompatiblePages,
  } = useGroupings(caseID ?? '', caseFileID ?? '');

  useEffect(() => {
    if (pages.length > 0 && !pageID) {
      const firstPageID = pages[0].id;
      navigate(`${firstPageID}`);
      const currentPageDiv = document.getElementById(`chip-${firstPageID}`);
      if (currentPageDiv) {
        currentPageDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  }, [pages]);

  useEffect(() => {
    if (caseID && caseFileID) {
      logUserActivity({
        activity: 'case:grouping',
        case_id: caseID,
        file_id: caseFileID,
      });
    }
  }, [caseID, caseFileID]);

  const groupingActionOnClicks: GroupingActionOnClicks = {
    splitAt,
    mergeAt,
    attachAt,
    detachAt,
  };

  const getButtonEnabledStatuses: GroupingActionStatuses = {
    canSplitAt,
    canMergeAt,
    canAttachAt,
    canDetachAt,
  };

  const { currentPageIndex, currentPage } = useMemo(() => {
    if (!pageIndexById || !pageID) {
      return { currentPageIndex: 0, currentPage: undefined };
    }

    const currentPageIndex = pageIndexById.get(pageID) ?? 0;
    const currentPage = pages?.[currentPageIndex];
    return { currentPageIndex, currentPage };
  }, [pageIndexById, pageID, pages]);

  if (!pageID || pages == null || pages.length === 0) {
    return <Loading />;
  }

  const onChangePage = (nextIndex: number) => {
    const newPage = pages?.[nextIndex - 1];
    navigate(`${newPage?.id}`);
    const currentPageDiv = document.getElementById(`chip-${newPage?.id}`);
    if (currentPageDiv) {
      currentPageDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const resetEdgesOnClick = async () => {
    if (qAorProcessor === undefined || pages.length === 0) {
      return;
    }

    setIsMergeAllModalOpen(false);

    await resetEdges(qAorProcessor, startPage, endPage);
  };

  const buildGroupingsOnClick = async () => {
    if (endPage && startPage && currentPage) {
      setIsBuildGroupingsModalOpen(false);

      const buildUpToPage = currentPage.page_number < endPage ? currentPage.page_number : endPage;

      const { status: buildGroupingsStatus } = await buildGroupings(startPage, buildUpToPage);
      if (buildGroupingsStatus === 'rejected') {
        return;
      }
      refetchCaseFile();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        height: '100%',
      }}
    >
      <Modal isOpen={isMergeAllModalOpen} onClose={() => setIsMergeAllModalOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              color: 'var(--color-text-primary)',
              marginBottom: '1rem',
            }}
          >
            Merge All Document Groupings?
          </span>
          <span>
            This action will remove all current groupings and merge your page range into a single
            document. Would you like to proceed?
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              onClick={() => setIsMergeAllModalOpen(false)}
              className="sm-button"
              style={{
                marginRight: '1rem',
              }}
            >
              Cancel
            </button>
            <button
              onClick={resetEdgesOnClick}
              type="button"
              className="sm-button sm-button-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isBuildGroupingsModalOpen} onClose={() => setIsBuildGroupingsModalOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              color: 'var(--color-text-primary)',
              marginBottom: '1rem',
            }}
          >
            Build Groupings for File?
          </span>
          <span>
            {`Building groupings will change any documents in the review tab up to this point in the
              file to reflect the new groupings. This will complete build groupings from the start of 
              your page range up to the current grouping. Would you like to proceed?`}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              onClick={() => setIsBuildGroupingsModalOpen(false)}
              className="sm-button"
              style={{
                marginRight: '1rem',
              }}
            >
              Cancel
            </button>
            <button
              onClick={buildGroupingsOnClick}
              type="button"
              className="sm-button sm-button-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <div className="document-grouping-container" id="document-display">
        <NavBar
          currentPage={currentPageIndex + 1}
          page={currentPage}
          numberOfPages={pages ? pages.length : 0}
          onChangePage={onChangePage}
          onPreviousButtonClick={() => {}}
          onNextButtonClick={() => {}}
          nextButtonDisabled={true}
          previousButtonDisabled={true}
          showContentToolbar={false}
          showFileProcessorToolbar={false}
          showZoomRotateToolbar={true}
        />

        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '20%',
              maxWidth: 270,
              backgroundColor: Theme.palette.selectedGrey.main,
            }}
          >
            <span
              className="sm-back-button"
              onClick={() => {
                navigate('../files');
              }}
              style={{ width: 'fit-content', padding: '0.5rem' }}
            >
              <UpArrowWithTail
                style={{
                  color: '#1E407D',
                  marginRight: '0.5rem',
                }}
                transform="rotate(270)"
              />
              <span className="sm-back-button-text sm-button-text">Back to files</span>
            </span>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5rem' }}>
              <GroupingPageRange
                currentStartPage={startPage ?? 1}
                currentEndPage={endPage ?? pages.length}
                totalNumberOfPages={pages.length}
                onChangePage={onChangePageRange}
              />
            </div>
            <div
              style={{
                height: window.innerHeight - 250,
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {Object.values(groupedDocumentTree).map((group: Array<PageObject | PageObject[]>) => (
                <DocumentChip
                  documentPages={group}
                  currentPage={currentPage?.id}
                  startPage={startPage}
                  endPage={endPage}
                />
              ))}
            </div>
          </div>
          <div style={{ width: '80%' }}>
            <DocumentScroller
              pages={documentScrollerCompatiblePages ?? []}
              searchResults={[]}
              currentMode="grouping"
              dismissDocumentEdge={() => {}}
              pageDepthByPageID={depthAt}
              groupingActionOnClicks={groupingActionOnClicks}
              isGrouping
              getGroupingButtonDisabledStatuses={getButtonEnabledStatuses}
              groupIDByPageID={groupIDByPageID}
              attachmentIDByPageID={attachmentIDByPageID}
            />
          </div>
        </div>
      </div>

      <Tooltip title="Reset all document edges for your page range.">
        <div
          style={{
            position: 'fixed',
            top: 63,
            right: 0,
            width: 'fit-content',
            marginRight: '2rem',
            marginBottom: '0.5rem',
          }}
        >
          {!(status === 'staged' || status === 'saving') && (
            <button
              type="button"
              className="sm-button"
              disabled={status === 'loading'}
              onClick={() => setIsMergeAllModalOpen(true)}
              style={{
                backgroundColor: 'var(--color-primary-main)',
                color: 'white',
              }}
            >
              Merge All
            </button>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Finalize file groupings">
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: 'fit-content',
            marginRight: '2rem',
            marginBottom: '0.5rem',
          }}
        >
          {status === 'staged' || status === 'saving' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              <span
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                {' '}
                Update In Progress
              </span>
            </div>
          ) : (
            <button
              type="button"
              className="sm-button"
              disabled={status === 'loading' || status === 'errored' || !canBuildGroupings(pageID)}
              onClick={() => setIsBuildGroupingsModalOpen(true)}
              style={{
                backgroundColor: 'var(--color-primary-main)',
                color: 'white',
                opacity: !canBuildGroupings(pageID) ? 0.5 : 1,
              }}
            >
              Mark Previous Documents as Done
            </button>
          )}
        </div>
      </Tooltip>
    </div>
  );
}

function AttachmentChip({
  documentPages,
  currentPage,
  isInRange,
}: {
  documentPages: PageObject[];
  currentPage: string | undefined;
  isInRange: boolean;
}) {
  const navigate = useNavigate();
  const firstPageOfAttachment = documentPages[0].id;
  return (
    <div
      style={{
        margin: '5px',
      }}
    >
      <div className={`attachment-chip${isInRange ? '-page-range' : ''}`}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {documentPages.map((page: PageObject) => {
            const isCurrentPage = currentPage === page.id;
            return (
              <span
                style={{
                  paddingBottom: '2px',
                  paddingTop: '2px',
                }}
              >
                <div
                  id={`chip-${page.id}`}
                  className="page-chip"
                  data-status={`${isCurrentPage}`}
                  style={{
                    justifyContent: 'flex-start',
                  }}
                  onClick={() => {
                    navigate(`${page.id}`);
                  }}
                >
                  <span
                    style={{
                      marginLeft: firstPageOfAttachment === page.id ? '20px' : '40px',
                      width: '90%',
                    }}
                  >
                    {firstPageOfAttachment === page.id && (
                      <span style={{ padding: '4px' }}>
                        <Attachment />
                      </span>
                    )}
                    {`Page ${page.page_number}`}
                  </span>
                  {page.document_status !== 'GROUPING' && (
                    <div style={{ alignItems: 'right', marginRight: '0.4rem' }}>
                      <CheckMark />
                    </div>
                  )}
                </div>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DocumentChip({
  documentPages,
  currentPage,
  startPage,
  endPage,
}: {
  documentPages: Array<PageObject | PageObject[]>;
  currentPage: string | undefined;
  startPage: number | null;
  endPage: number | null;
}) {
  const navigate = useNavigate();
  const inPageRange = documentPages.some((item) => {
    if (Array.isArray(item)) {
      return item.some(
        (page) => page.page_number >= (startPage ?? 1) && page.page_number <= (endPage ?? 0),
      );
    }
    return item.page_number >= (startPage ?? 1) && item.page_number <= (endPage ?? 0);
  });

  return (
    <div
      style={{
        margin: '6px',
      }}
    >
      <div
        className={`document-chip${inPageRange ? '-page-range' : ''}`}
        style={{
          marginTop: '0.5rem',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {documentPages.map((page) => {
            const isCurrentPage = currentPage === page.id;
            return !Array.isArray(page) ? (
              <div
                id={`chip-${page.id}`}
                className="page-chip"
                data-status={`${isCurrentPage}`}
                style={{ margin: '2px' }}
                onClick={() => {
                  navigate(`${page.id}`);
                }}
              >
                <div style={{ marginLeft: '1rem', width: '95%' }}>{`Page ${page.page_number}`}</div>
                {page.document_status !== 'GROUPING' && (
                  <div style={{ alignItems: 'right', marginRight: '1rem' }}>
                    <CheckMark />
                  </div>
                )}
              </div>
            ) : (
              <AttachmentChip
                documentPages={page}
                currentPage={currentPage}
                isInRange={inPageRange}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export type GroupingActionOnClicks = {
  splitAt: (pageID: string) => void;
  mergeAt: (pageID: string) => void;
  attachAt: (pageID: string) => void;
  detachAt: (pageID: string) => void;
};

export type GroupingActionStatuses = {
  canSplitAt: (pageID: string) => boolean;
  canMergeAt: (pageID: string) => boolean;
  canAttachAt: (pageID: string) => boolean;
  canDetachAt: (pageID: string) => boolean;
};
