export default function Attachment() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
      <path
        d="M9.39585 3.22289V11.1875C9.39585 13.3391 7.65163 15.0834 5.50002 15.0834C3.34841 15.0834 1.60419 13.3391 1.60419 11.1875V3.51391C1.60419 2.0795 2.767 0.916687 4.20141 0.916687C5.63582 0.916687 6.79863 2.0795 6.79863 3.51391V11.1491C6.79863 11.8663 6.21722 12.4477 5.50002 12.4477C4.78282 12.4477 4.20141 11.8663 4.20141 11.1491V4.21126"
        stroke="#344054"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
