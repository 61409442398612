import React, { useCallback } from 'react';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useUpdatePageHidden } from '../gql/useUpdatePageHidden';
import IconVisible from '../../../components/icons/IconVisible';
import IconHidden from '../../../components/icons/IconHidden';

function PageHiddenControl({ pageID, isHidden }: Props) {
  const { mutateAsync: hidePageMutation } = useUpdatePageHidden();
  const handleChangeHiddenStatus = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      hidePageMutation({ pageID: pageID, hidden: !isHidden });
    },
    [pageID, isHidden],
  );

  const HideIconComponent = isHidden ? IconHidden : IconVisible;
  return (
    <Tooltip title={isHidden ? 'Show' : 'Hide'} placement="top">
      <Box sx={{ color: 'modernGrey.default', height: '14px', display: 'flex', cursor: 'pointer' }}>
        <HideIconComponent onClick={handleChangeHiddenStatus} width="0.9rem" height="0.9rem" />
      </Box>
    </Tooltip>
  );
}

type Props = {
  pageID: number;
  isHidden: boolean;
  sx?: SxProps;
};

export default React.memo(PageHiddenControl);
