import React from 'react';

export default function IconFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
      {...props}
    >
      <path
        d="M3.33334 12.4998C3.33334 12.4998 4.16668 11.6665 6.66668 11.6665C9.16668 11.6665 10.8333 13.3332 13.3333 13.3332C15.8333 13.3332 16.6667 12.4998 16.6667 12.4998V3.33317C16.6667 3.33317 15.8333 4.1665 13.3333 4.1665C10.8333 4.1665 9.16668 2.49984 6.66668 2.49984C4.16668 2.49984 3.33334 3.33317 3.33334 3.33317M3.33334 18.3332L3.33334 1.6665"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
