import { GridRenderCellParams } from '@mui/x-data-grid';
import EditHoverCellEffect from '../../../../components/common/Table/EditHoverCellEffect';
import { compareAlphaNumeric } from '../../../../utils/compare';
import { isRowEditable } from '../utils/tableUtils';

export function DocumentTypeCell(params: GridRenderCellParams) {
  const contentTypes = params.row['document-tags']
    ?.map((type) => type.value)
    .filter(Boolean)
    .sort(compareAlphaNumeric.asc);
  const displayString = contentTypes?.join(', ') ?? '';
  return (
    <EditHoverCellEffect
      params={params}
      cellValue={displayString}
      isEditable={isRowEditable(params.row)}
    />
  );
}
