import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Auth } from '@aws-amplify/auth';
import { CircularProgress } from '@mui/material';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import EnterVerificationCode from './EnterVerificationCode';
import MFAVerificationSuccessful from './MFAVerificationSuccessful';
import BackButton from '../../../components/common/BackButton';
import { updatePhoneNumber } from '../../../api';

export default function SetupMFAUsingSMS({ user, back, onComplete, nextText }) {
  const [page, setPage] = useState('setPhoneNumber');
  const [errorMessage, setErrorMessage] = useState('');

  async function submitSMSVerificationCode(code) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
      await setSMSAsMFA();
      return setPage('MFAsuccess');
    } catch (err) {
      setErrorMessage('Error verifying phone number');
    }
  }

  async function sendSMSVerificationCode(phoneNumber) {
    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorMessage('Invalid phone number');
      return;
    }
    try {
      await updatePhoneNumber(phoneNumber);
    } catch (err) {
      setErrorMessage('Problem updating phone number');
      return;
    }

    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
    } catch (err) {
      setErrorMessage('Error sending verification code');
      return;
    }
    setPage('EnterVerificationCode');
  }

  async function setSMSAsMFA() {
    try {
      await Auth.setPreferredMFA(user, 'SMS');
    } catch (err) {
      setErrorMessage('Error setting up MFA');
    }
  }

  const onAlertClose = () => {
    setErrorMessage('');
  };

  const goBack = () => {
    switch (page) {
      case 'setPhoneNumber':
        back();
        break;
      case 'EnterVerificationCode':
        setPage('setPhoneNumber');
        break;
      default:
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: '80%' }}>
        {page !== 'MFAsuccess' && (
          <BackButton
            onClick={goBack}
            style={{
              float: 'left',
            }}
          />
        )}
        {page === 'loading' && <CircularProgress />}

        {page === 'setPhoneNumber' && (
          <SetPhoneNumber sendSMSVerificationCode={sendSMSVerificationCode} />
        )}
        {page === 'EnterVerificationCode' && (
          <EnterVerificationCode
            onSubmit={submitSMSVerificationCode}
            title="Enter the verification code sent to your phone below"
          />
        )}
        {page === 'MFAsuccess' && (
          <MFAVerificationSuccessful nextText={nextText} next={onComplete} />
        )}
      </div>

      {errorMessage !== '' && (
        <Alert severity="error" onClose={onAlertClose} style={{ marginTop: '.5rem' }}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

function SetPhoneNumber({ sendSMSVerificationCode }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <div style={{ height: '100%' }}>
      <div style={{ fontSize: '18px', textAlign: 'center', marginBottom: '2rem' }}>
        Enter your phone number
      </div>
      <div style={{ textAlign: 'center' }}>
        <Input
          country="CA"
          value={phoneNumber}
          onChange={setPhoneNumber}
          placeholder="(123) 456-7890"
        />
      </div>
      <br />
      <br />
      <p
        style={{
          fontSize: '0.875rem',
          textAlign: 'center',
          color: 'rgba(0,0,0,0.75)',
        }}
      >
        Tap Next to verify your account with your phone number.
      </p>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => sendSMSVerificationCode(phoneNumber)}
      >
        Next
      </Button>
    </div>
  );
}
