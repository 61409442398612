import { Archive, DoneAll, HourglassBottom, Share, Update } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ClickAwayListener, IconButton, Menu, MenuItem, MenuList, Paper } from '@mui/material';
import { useState } from 'react';
import DeleteDialog from '../../components/common/DeleteDialog';
import AddUsersMenu from '../Timeline/Components/AddUsersMenu';
import CaseUpdate from './CaseUpdate';
import { useDeleteCase, useRemoveSelfFromCase, useSetCaseStatus } from './useCases';
import { useUser } from '../../library/contexts/AuthContext';

function CaseSettings({
  caseID,
  caseName,
  dueDate,
  fullName: patientName,
  dateOfBirth,
  claimReason,
  dateOfInjury,
  setEventAlert,
  caseStatus,
  userID: caseOwnerID,
  case_type_id,
}) {
  const { user } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [UpdateMenuOpen, setUpdateMenuOpen] = useState(false);
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const [DeletePromptOpen, setDeletePromptOpen] = useState(false);
  const [removePromptOpen, setRemovePromptOpen] = useState(false);

  const { mutate: deleteCase } = useDeleteCase();
  const { mutate: removeCase } = useRemoveSelfFromCase();
  const { mutateAsync: setCaseStatus } = useSetCaseStatus();
  const handleMenuClick = (event) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleUpdateMenuOpen = () => {
    setUpdateMenuOpen(true);
    handleMenuClose();
  };

  const handleUpdateMenuClose = () => {
    setUpdateMenuOpen(false);
  };

  const handleShareMenuOpen = () => {
    setShareMenuOpen(true);
    handleMenuClose();
  };

  const handleShareMenuClose = () => {
    setShareMenuOpen(false);
  };

  const handleCaseStatusToggle = async () => {
    const postedCaseStatus = () => {
      if (caseStatus === 'READY') {
        return 'CLOSED';
      }
      if (caseStatus === 'CLOSED') {
        return 'READY';
      }
      return caseStatus;
    };
    handleMenuClose();
    await setCaseStatus({
      caseID,
      caseStatus: postedCaseStatus(),
    });
    setEventAlert('Your case status has been updated successfully.');
  };
  const onAddUser = () => {
    setShareMenuOpen(true);
  };

  const openDeletePrompt = () => {
    setDeletePromptOpen(true);
    handleMenuClose();
  };

  const handleDeletePromptClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setDeletePromptOpen(false);
    setRemovePromptOpen(false);
  };
  const handleDelete = () => {
    setDeletePromptOpen(false);
    deleteCase({ userID: user?.username, caseID });
    setEventAlert('Your case has been successfully archived.');
  };
  const handleRemove = () => {
    setRemovePromptOpen(false);
    removeCase({ userID: user?.username, caseID });
    setEventAlert('You have been removed from this case.');
  };

  const openRemoveAccessPrompt = () => {
    setRemovePromptOpen(true);
    handleMenuClose();
  };
  return (
    <>
      <IconButton aria-label="case-options" size="small" onClick={handleMenuClick} edge="end">
        <MoreVertIcon
          aria-haspopup="true"
          aria-controls="simple-menu"
          style={{ margin: 'auto', cursor: 'pointer' }}
        />
      </IconButton>
      <Menu
        open={isMenuOpen}
        keepMounted
        anchorEl={anchorEl}
        style={{ zIndex: 1 }}
        onClose={handleMenuClose}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <MenuList id="menu-list-grow" sx={{ '& li': { fontSize: '0.875rem' } }}>
              <MenuItem onClick={handleShareMenuOpen} aria-label="share-case">
                <Share style={{ marginRight: '0.25rem' }} />
                Share Case
              </MenuItem>

              <MenuItem onClick={handleUpdateMenuOpen} aria-label="update-case">
                <Update style={{ marginRight: '0.25rem' }} />
                Update Case
              </MenuItem>

              {caseStatus === 'READY' ? (
                <MenuItem onClick={handleCaseStatusToggle} aria-label="toggle-case-status">
                  <DoneAll style={{ marginRight: '0.25rem' }} />
                  Close Case
                </MenuItem>
              ) : caseStatus === 'CLOSED' ? (
                <MenuItem onClick={handleCaseStatusToggle} aria-label="toggle-case-status">
                  <HourglassBottom style={{ marginRight: '0.25rem' }} />
                  Reopen Case
                </MenuItem>
              ) : null}
              {user?.username !== caseOwnerID ? (
                <MenuItem onClick={openRemoveAccessPrompt} aria-label="delete-case">
                  <Archive style={{ marginRight: '0.25rem' }} />
                  Remove
                </MenuItem>
              ) : (
                <MenuItem onClick={openDeletePrompt} aria-label="delete-case">
                  <Archive style={{ marginRight: '0.25rem' }} />
                  Archive Case
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Menu>

      {UpdateMenuOpen ? (
        <CaseUpdate
          caseID={caseID}
          caseName={caseName}
          dateOfBirth={dateOfBirth}
          dueDate={dueDate}
          claimReason={claimReason}
          dateOfInjury={dateOfInjury}
          patientName={patientName}
          menuOpen={UpdateMenuOpen}
          handleUpdateMenuClose={handleUpdateMenuClose}
          setEventAlert={setEventAlert}
          caseTypeId={case_type_id}
        />
      ) : null}

      {shareMenuOpen ? (
        <AddUsersMenu
          addUsersOpen={shareMenuOpen}
          anchorEl={anchorEl}
          caseID={caseID}
          handleUserAddClose={handleShareMenuClose}
          onAddUser={onAddUser}
          onClick={() => onAddUser}
          setEventAlert={setEventAlert}
        />
      ) : null}

      <DeleteDialog
        open={DeletePromptOpen}
        onClose={handleDeletePromptClose}
        onConfirm={handleDelete}
        title="Archive This Case?"
        confirmText="Confirm Archive"
        contentText="This action will archive all documents, reports, and data associated with the case."
      />

      <DeleteDialog
        open={removePromptOpen}
        onClose={handleDeletePromptClose}
        onConfirm={handleRemove}
        title="Remove Yourself From This Case?"
        contentText="This action will remove you from the case. You will no longer have access to the case or its documents."
      />
    </>
  );
}

export default CaseSettings;
