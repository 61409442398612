import { useState } from 'react';
import { PageRange } from '../Timeline/TimelineList/TimelinePageRange';

export default function GroupingPageRange({
  currentStartPage,
  currentEndPage,
  totalNumberOfPages,
  onChangePage,
}: {
  currentStartPage: number;
  currentEndPage: number;
  totalNumberOfPages: number;
  onChangePage: (startPage: number, endPage: number) => void;
}) {
  const [startPage, setStartPage] = useState(currentStartPage);
  const [endPage, setEndPage] = useState(currentEndPage);

  const onChangePageRange = () => {
    if (startPage < 1) {
      setStartPage(1);
      onChangePage(1, endPage);
    } else if (endPage < startPage) {
      if (startPage <= totalNumberOfPages) {
        setEndPage(startPage);
      } else {
        setStartPage(totalNumberOfPages);
        setEndPage(totalNumberOfPages);
      }
    } else if (endPage > totalNumberOfPages) {
      setEndPage(totalNumberOfPages);
      onChangePage(startPage, totalNumberOfPages);
    } else {
      onChangePage(startPage, endPage);
    }
  };

  return (
    <PageRange
      startPage={startPage}
      endPage={endPage}
      setStartPage={setStartPage}
      setEndPage={setEndPage}
      onChangePage={onChangePageRange}
    />
  );
}
