import React from 'react';

export default function IconVisible(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? '20'}
      height={props.height ?? '20'}
      viewBox="0 2 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.61672 9.47644C1.52574 9.33237 1.48024 9.26034 1.45478 9.14923C1.43565 9.06577 1.43565 8.93416 1.45478 8.8507C1.48024 8.73959 1.52574 8.66756 1.61672 8.52349C2.36861 7.33294 4.60667 4.32324 8.01734 4.32324C11.428 4.32324 13.6661 7.33294 14.418 8.52349C14.5089 8.66756 14.5544 8.73959 14.5799 8.8507C14.599 8.93416 14.599 9.06577 14.5799 9.14923C14.5544 9.26034 14.5089 9.33237 14.418 9.47644C13.6661 10.667 11.428 13.6767 8.01734 13.6767C4.60667 13.6767 2.36861 10.667 1.61672 9.47644Z"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.33621"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01734 11.0043C9.12429 11.0043 10.0217 10.1069 10.0217 8.99997C10.0217 7.89302 9.12429 6.99566 8.01734 6.99566C6.91039 6.99566 6.01303 7.89302 6.01303 8.99997C6.01303 10.1069 6.91039 11.0043 8.01734 11.0043Z"
        stroke={props?.style?.color ?? 'var(--color-icon-grey)'}
        strokeWidth="1.33621"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
