import CropFreeIcon from '@mui/icons-material/CropFree';
import { Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import DocumentSkeleton from '../../components/DocumentScrolling/DocumentSkeleton';
import { AppContext } from '../../library/contexts/AppContext';
import { useSearchQuery } from '../../library/utilities/useSearch';
import DocumentPDFHeader from '../PDFDisplay/DocumentPDFHeader';
import TimelinePDFHeader from '../PDFDisplay/TimelinePDFHeader';
import useDocumentDesciptor from '../Documents/gql/useDocumentDescriptors';
import useTimelineEntries from '../Timeline/gql/useTimelineEntries';
import useDocumentSearchStore from '../Timeline/useDocumentSearchStore';
import { useTimelineList } from '../Timeline/useTimeline';
import DocumentScroller from './DocumentScroller';
import { useTimelineEntryDescriptors } from '../Timeline/gql/useTimelineEntryDescriptors';
import useUpdatePageViewed from '../Page/gql/updatePageViewed';
import { PageControls } from '../../components/DocumentScrolling/components/PageControls';
import useFeatureFlags from '../../config/useFeatureFlags';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';

export default function PDFDisplaySelector() {
  const { reportEditorOpen } = useContext(AppContext);
  const { caseID, pageID } = useParams();
  const location = useLocation();
  const [updatePageToViewed] = useUpdatePageViewed();

  const { data: timelineList } = useTimelineList(caseID);
  const currentTimeline = useMemo(
    () => timelineList?.find((timeline) => timeline.isDefault),
    [timelineList],
  );

  useEffect(
    () => () => {
      if (pageID != null) {
        updatePageToViewed({
          data: {
            pageID: +pageID,
            viewed: 1,
          },
        });
      }
    },
    [pageID],
  );

  const timelineView = location.pathname.indexOf('/timeline') > -1;

  const noPageMessage = useMemo(() => {
    if (!currentTimeline?.id && timelineView) {
      return 'Please select a timeline to view.';
    }
    if (timelineView) {
      return 'Click on a timeline entry to open it here';
    }
    return 'Select a document or page to view it here';
  }, [currentTimeline, timelineView]);

  return (
    <div
      id="document-display"
      style={{
        margin: 'auto',
        width: '100%',
        height: '100%',
        display: reportEditorOpen ? 'none' : 'block',
      }}
    >
      {location.pathname.indexOf('/documents') > -1 && pageID !== undefined ? (
        <DocumentDisplay />
      ) : null}

      {timelineView && pageID !== undefined ? <TimelineDisplay /> : null}

      {pageID === undefined && (
        <NoPage
          header={!currentTimeline?.id && timelineView && 'No Timeline Selected'}
          message={noPageMessage}
        />
      )}
    </div>
  );
}

function NoPage(props) {
  const { header, message } = props;
  return (
    <div style={{ position: 'relative', top: 'calc(50% - 128px)' }}>
      <div style={{ textAlign: 'center' }}>
        <CropFreeIcon style={{ color: 'darkgrey', fontSize: '104px' }} />
        <div style={{ opacity: '0.7' }}>
          {header && <Typography>{header}</Typography>}
          {message && <Typography variant="h6">{message}</Typography>}
        </div>
      </div>
    </div>
  );
}

function DocumentDisplay() {
  const { caseID } = useParams();
  const [searchStr, filters] = useDocumentSearchStore((state) => [state.searchStr, state.filters]);

  const keywordSearchResult = useSearchQuery(caseID, searchStr);

  const documentListQuery = useDocumentDesciptor({
    caseId: caseID,
    filters,
    keywordSearchConstraints: keywordSearchResult?.data,
    searchKeyword: searchStr,
  });
  const documents = documentListQuery?.data?.documentListDescriptor ?? [];
  const allPages = useMemo(
    () =>
      documents.flatMap((doc) =>
        doc?.pages.map((page) => ({ ...page, documentID: doc.documentID })),
      ) ?? [],
    [documentListQuery],
  );

  if (documentListQuery.loading) {
    return <DocumentSkeleton />;
  }
  return (
    <>
      <DocumentPDFHeader documentsList={documents} pageDescriptorList={allPages} />
      <div
        id="pdf-display-container"
        style={{
          height: 'calc(100% - 86px)',
          overflowY: 'hidden',
          textAlign: 'center',
        }}
      >
        <DocumentScroller
          pages={allPages}
          currentMode="documents"
          searchResults={searchStr.length > 0 ? keywordSearchResult.data : []}
        />
      </div>
    </>
  );
}

export function TimelineDisplay() {
  const { caseID, timelineID } = useParams();
  const [searchStr, filters] = useDocumentSearchStore((state) => [state.searchStr, state.filters]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: { HidePages = false } = {} } = useFeatureFlags();
  const isFileProcessor = useIsFileProcessor();
  const allowHidePages = HidePages && !isFileProcessor;

  const startPageNumber = searchParams.get('startPage')
    ? Number(searchParams.get('startPage'))
    : undefined;
  const endPageNumber = searchParams.get('endPage')
    ? Number(searchParams.get('endPage'))
    : undefined;
  const documentID = useMemo(() => {
    return searchParams.get('documentID') ?? undefined;
  }, [searchParams]);
  const { data: timelineSearchResults } = useSearchQuery(caseID, searchStr);
  const timelineEntriesQuery = useTimelineEntries({
    timelineId: timelineID,
    filters,
    keywordSearchConstraints: timelineSearchResults,
    searchKeyword: searchStr,
    documentID: documentID ? [documentID] : undefined,
    startPageNumber: startPageNumber,
    endPageNumber: endPageNumber,
  });
  const timelineEntryDescriptorsQuery = useTimelineEntryDescriptors({
    caseID,
    timelineID,
    documentID: documentID ? [documentID] : undefined,
    startPageNumber: startPageNumber,
    endPageNumber: endPageNumber,
  });

  const descriptorPages = useMemo(
    () =>
      timelineEntryDescriptorsQuery.data?.timelineEntryDescriptors.flatMap((entry) =>
        entry.pages.map((page) => ({ ...page, entryID: entry.id })),
      ) ?? [],
    [timelineEntryDescriptorsQuery],
  );

  const pages = useMemo(() => {
    const descriptorPageMap = new Map();

    if (timelineEntryDescriptorsQuery.data?.timelineEntryDescriptors) {
      timelineEntryDescriptorsQuery.data.timelineEntryDescriptors.forEach((entry) =>
        entry.pages.forEach((page) => {
          descriptorPageMap.set(page.id, { ...page, entryID: entry.id });
        }),
      );
    }

    if (timelineEntriesQuery.data?.entries) {
      timelineEntriesQuery.data.entries.forEach((entry) =>
        entry.pages.forEach((page) => {
          if (descriptorPageMap.has(page.id)) {
            descriptorPageMap.set(page.id, {
              ...page,
              entryID: entry.id,
              pageDate: entry.entryDate,
            });
          }
        }),
      );
    }

    return Array.from(descriptorPageMap.values());
  }, [timelineEntriesQuery, timelineEntryDescriptorsQuery]);

  if (
    timelineEntriesQuery.data === undefined ||
    timelineEntryDescriptorsQuery.data === undefined ||
    timelineEntriesQuery.loading
  ) {
    return <DocumentSkeleton />;
  }
  return (
    <>
      <TimelinePDFHeader
        pageList={descriptorPages}
        timelineEntriesDisplayed={timelineEntriesQuery.data.entries}
        timelineEntryDescriptors={timelineEntryDescriptorsQuery.data?.timelineEntryDescriptors}
      />
      <div
        id="pdf-display-container"
        style={{
          height: `calc(100% -  64px)`,
          overflowY: 'hidden',
          textAlign: 'center',
        }}
      >
        {allowHidePages && <PageControls />}
        <DocumentScroller
          searchResults={timelineSearchResults}
          pages={pages}
          currentMode="timeline"
        />
      </div>
    </>
  );
}
