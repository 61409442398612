import { useState, memo } from 'react';
import { Card, Typography, Box, Collapse, styled } from '@mui/material';
import blue from '@mui/material/colors/blue';
import Circle from '@mui/icons-material/Circle';

type DocumentCardProps = {
  documentName: string;
  displayDate: string;
  totalPages: number | '';
  pages: { id: number; page_number: number }[];
  onClick: () => void;
  expanded: boolean;
};

const StyledCard = styled(Card)<{ isExpanded: boolean }>(({ isExpanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  padding: '10px',
  boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
  borderRadius: '10px',
  width: '100%',
  cursor: 'pointer',
  marginBottom: '10px',
  border: isExpanded ? `1px solid ${blue[900]}` : 'transparent',
}));

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const DateTypography = styled(Typography)({
  fontSize: '12px',
  alignSelf: 'flex-start',
  width: '100%',
  fontWeight: 'bold',
});

const TitleTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'bold',
  flexGrow: 1,
  marginRight: '8px',
});

const PagesTypography = styled(Typography)({
  fontSize: '12px',
  color: '#666',
});

function DocumentCard(props: DocumentCardProps) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    props.onClick();
  };

  return (
    <div style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
      <DateTypography sx={{ color: 'dropdown.subgroupHeader' }}>
        {props?.displayDate}
      </DateTypography>
      <StyledCard isExpanded={props?.expanded} onClick={handleExpandClick}>
        <StyledBox>
          <TitleTypography>{props?.documentName}</TitleTypography>
          <PagesTypography>{props?.totalPages}</PagesTypography>
        </StyledBox>
        <Collapse in={props.expanded} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            justifyItems="center"
            width="100%"
          >
            {props?.pages?.map((page: { id: number; page_number: number }) => (
              <Typography key={page.id} variant="caption">
                <Circle sx={{ fontSize: '12px', marginRight: '4px', color: 'secondary.main' }} />
                {page.page_number}
              </Typography>
            ))}
          </Box>
        </Collapse>
      </StyledCard>
    </div>
  );
}

export default memo(DocumentCard);
