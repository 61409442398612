import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTextFromNoteScreenshot } from '../../../library/utilities/useSearch';
import usePDFViewerStore, { documentRotationValues } from '../../Case/usePDFViewerStore';
import useDisplayStore from '../useDisplayStore';
import ScreenCaptureListener from '../../ScreenCapture';
import { Page as PageObject } from '../types/timelineTypes';
import { BoundingBoxType } from '../../ScreenCapture/types';
import useScreenCaptureStore from '../../ScreenCapture/useScreenCaptureStore';
import { getCaptureAreaRelativeToRotation } from '../../ScreenCapture/utils';

type PageScreenCaptureProps = {
  page: PageObject;
  documentRotation: documentRotationValues;
  documentZoom: number;
};

export default function PageScreenCapture({
  page,
  documentRotation,
  documentZoom,
}: PageScreenCaptureProps) {
  const ref = React.useRef<any>(null);
  const { documentWidth, documentHeight } = useDisplayStore((state) => state.PDFDimensions);
  const [width, setWidth] = useState<number>(documentWidth);
  const [height, setHeight] = useState<number>(documentHeight);
  const { caseID } = useParams();
  const isScreenCapturing = usePDFViewerStore((state) => state.isScreenCapturing);
  const addScreenCapture = useScreenCaptureStore((state) => state.addScreenCapture);

  const handleScreenCapture = async (screenCaptureAreaPercentage: BoundingBoxType) => {
    const captureAreaAdjustedForRotation = getCaptureAreaRelativeToRotation(
      screenCaptureAreaPercentage,
      documentRotation,
    );
    const capturedText: string = await getTextFromNoteScreenshot(
      caseID,
      page.documentID,
      page.pageNumber,
      captureAreaAdjustedForRotation,
    );

    addScreenCapture({
      coordinates: captureAreaAdjustedForRotation,
      capturedText: capturedText,
      pageID: String(page.id),
    });
  };

  useEffect(() => {
    setWidth(ref.current?.clientWidth || documentWidth);
    setHeight(ref.current?.clientHeight || documentHeight);
  }, [documentRotation, documentZoom, ref.current]);

  return (
    <Box
      ref={ref}
      className="page-screencapture"
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      <ScreenCaptureListener
        width={width}
        height={height}
        handleCapture={handleScreenCapture}
        on={isScreenCapturing}
      />
    </Box>
  );
}
