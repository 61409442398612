/* eslint-disable no-restricted-syntax */

import { DocumentEdge, PageObject } from '../../../api';

export type Edge = {
  type: DocumentEdge['type'];
  page_id: PageObject['id'];
  page_number: PageObject['page_number'];
};

export type EdgeDiff = {
  dismissing: Edge[];
  confirming: Edge[];
};

export function compareEdges(a: DocumentEdge, b: DocumentEdge) {
  if (a.type === 'start' && b.type === 'end') {
    return -1;
  }
  if (a.type === 'end' && b.type === 'start') {
    return 1;
  }
  return 0;
}
