import { useState } from 'react';
import { useParams } from 'react-router-dom';

import PageScroller from './components/PageScroller';
import { ZoomAndToolbar } from '../../containers/DuplicatesByDocument/DocumentCompareHeader';
import { PreviewPagesWrapper } from '../../containers/ReportEditor/IndexReports/DocumetPreviewer/usePreviewPagesNew';

type Props = {
  firstDocumentID: number;
  secondDocumentID: number;
  firstDocumentStyle?: any;
  secondDocumentStyle?: any;
  zoom: number;
  setFirstDocumentPageCount: (count: number) => void;
  setSecondDocumentPageCount: (count: number) => void;
  setAreDocumentScrollsSynced: (value: boolean) => void;
  areDocumentScrollsSynced: boolean;
  setZoom: (value: number) => void;
  handleMarkAsDuplicate: (args: { document_id: number; duplicate_of: number }) => void;
  isFirstDocumentDuplicate?: boolean;
  isSecondDocumentDuplicate?: boolean;
};

export default function DocumentComparer({
  firstDocumentID,
  isFirstDocumentDuplicate,
  isSecondDocumentDuplicate,
  handleMarkAsDuplicate,
  secondDocumentID,
  firstDocumentStyle,
  secondDocumentStyle,
  setFirstDocumentPageCount,
  setSecondDocumentPageCount,
  zoom,
  setZoom,
  areDocumentScrollsSynced,
  setAreDocumentScrollsSynced,
}: Props) {
  const [isUpdating, setIsUpdating] = useState(false);
  const { caseID = '' } = useParams();

  const handleMarkAsDuplicateInternal = async (documentId: number, duplicateOf: number) => {
    try {
      setIsUpdating(true);
      await handleMarkAsDuplicate({ document_id: documentId, duplicate_of: duplicateOf });
    } catch (e) {
      console.log(e);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', backgroundColor: 'white' }}>
      <div style={{ width: '48%' }}>
        <PreviewPagesWrapper
          documentIds={[String(firstDocumentID)]}
          caseId={caseID}
          hideBlanks={true}
        >
          <PageScroller
            containerId="first-document-scroller"
            style={firstDocumentStyle ?? {}}
            pageHeader={true}
            sharedZoom={zoom}
            setDocumentPageCount={setFirstDocumentPageCount}
            markAsDuplicate={() => handleMarkAsDuplicateInternal(firstDocumentID, secondDocumentID)}
            isDocumentDuplicate={isFirstDocumentDuplicate}
            isUpdating={isUpdating}
            documentId={firstDocumentID}
          />
        </PreviewPagesWrapper>
      </div>

      <ZoomAndToolbar
        zoom={zoom}
        setZoom={setZoom}
        areDocumentScrollsSynced={areDocumentScrollsSynced}
        setAreDocumentScrollsSynced={setAreDocumentScrollsSynced}
      />

      <div style={{ width: '48%' }}>
        <PreviewPagesWrapper
          documentIds={[String(secondDocumentID)]}
          caseId={caseID}
          hideBlanks={true}
        >
          <PageScroller
            documentId={secondDocumentID}
            containerId="second-document-scroller"
            style={secondDocumentStyle ?? {}}
            pageHeader={true}
            isUpdating={isUpdating}
            sharedZoom={zoom}
            setDocumentPageCount={setSecondDocumentPageCount}
            markAsDuplicate={() => handleMarkAsDuplicateInternal(secondDocumentID, firstDocumentID)}
            isDocumentDuplicate={isSecondDocumentDuplicate}
          />
        </PreviewPagesWrapper>
      </div>
    </div>
  );
}
