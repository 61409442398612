import {
  ClickAwayListener,
  Grow,
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import useNotes from './gql/useNotes';

function NotePhysicianFilter({ physician, setPhysician, onFocus }) {
  const { caseID } = useParams();
  const { data: notes } = useNotes({ case_id: caseID });
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const physicianNamesArray = [];
  notes?.forEach((note) => {
    if (
      note.physician != null &&
      note.physician !== '' &&
      !physicianNamesArray.includes(note.physician)
    ) {
      physicianNamesArray.push(note.physician);
    }
  });

  //Close the menu and drop the location ref
  const handlePhysicianMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleClick = (PhysicianName) => {
    setPhysician(PhysicianName);
    setMenuOpen(false);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        sx={{ display: 'inline' }}
      >
        <TextField
          onClick={() => {
            setMenuOpen(true);
            if (onFocus) {
              onFocus();
            }
          }}
          sx={{
            width: '95%',
            height: '1rem',
            '& .MuiOutlinedInput-input': {
              marginTop: -0.5,
            },
          }}
          name="Physician"
          value={physician}
          onChange={(e) => handleClick(e.target.value)}
          size="small"
          className="fs-exclude"
        />
      </Box>
      {physicianNamesArray.length > 0 && (
        <Popper
          open={menuOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -11],
              },
            },
          ]}
          transition
          sx={{ zIndex: 1400, width: '16rem' }}
        >
          {({ TransitionProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Grow {...TransitionProps}>
              <Paper
                variant="outlined"
                sx={{
                  marginTop: '9.5%',
                  maxHeight: '10rem',
                  overflowY: 'scroll',
                }}
              >
                <ClickAwayListener onClickAway={handlePhysicianMenuClose}>
                  <Box>
                    <MenuList id="menu-list-grow" sx={{ maxHeight: '300px', overflow: 'auto' }}>
                      {physicianNamesArray
                        .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
                        .map((physName) => (
                          <MenuItem
                            key={physName}
                            onClick={() => handleClick(physName)}
                            sx={{
                              flex: 90,
                            }}
                          >
                            {physName}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}
export default NotePhysicianFilter;
