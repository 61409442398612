import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { updateCaseDetails, caseDetails } from '../../../api';
import { GetCasesDocument } from '../../../__generated__/graphql';

const useUpdateCaseDetails = () => {
  const gqlClient = useApolloClient();

  return useCallback(
    async (caseId: string, data: caseDetails) => {
      await updateCaseDetails(caseId, data);

      gqlClient.refetchQueries({
        include: [GetCasesDocument],
      });
    },
    [gqlClient, updateCaseDetails],
  );
};

export default useUpdateCaseDetails;
