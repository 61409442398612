import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';

const fetchCaseTypes = async () => {
  const res = await authenticatedRequest<{ id: bigint; name: string }[]>({
    method: 'GET',
    url: `/api/v1/all-case-types`,
  });
  return res.data;
};

export const useCaseTypes = () => {
  return useQuery(['caseTypes'], fetchCaseTypes, {
    staleTime: 1000 * 60 * 5, // Keep data fresh for 5 minutes
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    onError: (error) => console.error('Error fetching case types:', error),
  });
};
