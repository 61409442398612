/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { useUpdateCaseUserLocationMutation } from '../../__generated__/graphql';
import { updateUserDetails, removeMFA } from '../../api';

Auth.configure({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  authenticationFlowType: 'USER_SRP_AUTH',
  Auth: {
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: window.location.origin,
      redirectSignOut: `${window.location.origin}/login`,
      responseType: 'code',
    },
  },
});

export const useUser = () => {
  const { user } = useContext(AuthContext);

  return useMemo(() => {
    const userId = user?.getUsername() ?? '';
    return {
      userId,
      user,
    };
  }, [user]);
};

export const AuthContext = React.createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [updateCaseUserLocation] = useUpdateCaseUserLocationMutation();
  const queryClient = useQueryClient();

  const checkSessionTimeout = (timeoutMinutes) => {
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    if (lastActiveTime) {
      const currentTime = Date.now();
      const timeDiff = currentTime - parseInt(lastActiveTime, 10);
      return timeDiff > timeoutMinutes * 60 * 1000;
    }
    return true; // if no lastActiveTime, assume session is expired
  };

  const getSession = useCallback(async () => {
    if (checkSessionTimeout(Number(process.env.REACT_APP_SESSION_TIMEOUT) ?? 60)) {
      await Auth.signOut();
      window.location.href = '/login';
      return null;
    }
    const thisUser = await Auth.currentAuthenticatedUser();
    const attributes = thisUser.attributes ?? thisUser.signInUserSession.idToken.payload;
    if (attributes) {
      setGivenName(attributes.given_name);
      setFamilyName(attributes.family_name);
      axios.defaults.headers.common.Authorization = `${thisUser.signInUserSession.idToken.jwtToken}`;
      setUser(thisUser);
      try {
        if (attributes?.identities) {
          // Only update user details if the user uses SSO
          await updateUserDetails();
        }
      } catch (error) {
        console.error('Failed to update user details:', error);
      }
      return thisUser;
    }

    return null;
  }, [setGivenName, setFamilyName, setUser]);

  const authorizeSession = useCallback(
    async (authorizedUser) => {
      axios.defaults.headers.common.Authorization = `${authorizedUser.signInUserSession.idToken.jwtToken}`;
      localStorage.setItem(
        'ReactAmplify.TokenKey',
        authorizedUser.signInUserSession.accessToken.jwtToken,
      );
      const attributes =
        authorizedUser.attributes ?? authorizedUser.signInUserSession.idToken.payload;

      setGivenName(attributes.given_name);
      setFamilyName(attributes.family_name);
    },
    [setGivenName, setFamilyName],
  );

  const authenticate = useCallback(
    async (username, password) => {
      const authUser = await Auth.signIn(username, password);
      setUser(authUser);
      localStorage.setItem('lastActiveTime', Date.now().toString());

      if (process.env.REACT_APP_MFA_REQUIRED || authUser.challengeName) {
        return authUser.challengeName || authUser.preferredMFA;
      }

      await authorizeSession(authUser);

      try {
        await updateUserDetails();
      } catch (error) {
        console.error('Failed to update user details:', error);
      }
      return null;
    },
    [setUser, authorizeSession],
  );

  const confirmSignIn = useCallback(
    async (code, challenge) => {
      await Auth.confirmSignIn(user, code, challenge);
      const newUser = await Auth.currentAuthenticatedUser();
      setUser(newUser);
      await authorizeSession(newUser);
    },
    [user, setUser, authorizeSession],
  );

  const saveUserLocation = useCallback(
    async (location, caseID) => {
      const pageIDRegex = /\/(\d+)\/?$/;
      const pageIDMatch = location.pathname?.match(pageIDRegex);
      const pageID = pageIDMatch ? pageIDMatch[1] : null;

      if (pageID && caseID && user) {
        await updateCaseUserLocation({
          variables: {
            data: {
              caseId: caseID,
              userId: user.username,
              pageId: +pageID,
              view: location.pathname.indexOf('/documents') > -1 ? 'document' : 'timeline',
            },
          },
        });
        queryClient.invalidateQueries(['case', caseID]);
      }
    },
    [user, updateCaseUserLocation, queryClient],
  );

  const logout = useCallback(
    async (location, caseID) => {
      if (location && caseID) {
        await saveUserLocation(location, caseID);
      }
      await Auth.signOut({ global: true });
      window.sessionStorage.clear();
      window.localStorage.clear();
    },
    [saveUserLocation],
  );

  const updateUserState = useCallback(async () => {
    const newUser = await Auth.currentAuthenticatedUser();
    setUser(newUser);
  }, [setUser]);

  const removeMFAFromAccount = useCallback(async () => {
    await removeMFA();
    await logout();
    window.location.href = '/login';
  }, [removeMFA, logout]);

  const forceChangePassword = useCallback(
    async (password) => {
      const session = await Auth.completeNewPassword(user, password);
      setUser(session);
      getSession();
      if (session.challengeName) {
        return session.challengeName;
      }
      return 'SUCCESS';
    },
    [user, setUser, getSession],
  );

  const refreshSession = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      console.log('refreshing');

      axios.defaults.headers.common.Authorization = token;

      return session.getIdToken().getJwtToken();
    } catch (e) {
      console.error('Unable to refresh Token', e);
    }

    return null;
  }, []);

  const SSOLogin = useCallback(
    async (provider) => {
      const authUser = await Auth.federatedSignIn({ provider: provider });
      localStorage.setItem('lastActiveTime', Date.now().toString());
      setUser(authUser);
      await authorizeSession(authUser);
    },
    [setUser, authorizeSession],
  );

  useEffect(() => {
    axios.interceptors.response.use(undefined, async (error) => {
      if (error?.response?.status === 401) {
        console.log('401, retrying');
        if (error.config._retry) {
          return logout();
        }
        return refreshSession().then((token) => {
          const originalRequestConfig = error.config;
          delete originalRequestConfig.headers.Authorization;
          originalRequestConfig.headers.Authorization = token;
          axios.defaults.headers.common.Authorization = token;
          originalRequestConfig._retry = true;
          return axios.request(originalRequestConfig);
        });
      }
      return Promise.reject(error);
    });
  });

  const authContextValue = useMemo(
    () => ({
      user,
      authenticate,
      confirmSignIn,
      getSession,
      logout,
      givenName,
      familyName,
      removeMFAFromAccount,
      updateUserState,
      forceChangePassword,
      SSOLogin,
      setUser,
    }),
    [
      user,
      authenticate,
      confirmSignIn,
      getSession,
      logout,
      givenName,
      familyName,
      removeMFAFromAccount,
      updateUserState,
      forceChangePassword,
      SSOLogin,
      setUser,
    ],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
