export default function DuplicatesByDocument() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="22" viewBox="0 0 35 22" fill="none">
      <g>
        <rect width="35" height="22" rx="11" fill="#F2F4F7" />
        <g clipPath="url(#clip0_29609_4790)">
          <path
            d="M18.2368 8V7.42901C18.2368 6.72158 18.2368 6.36786 18.0992 6.09766C17.9781 5.85998 17.7848 5.66674 17.5471 5.54563C17.2769 5.40796 16.9232 5.40796 16.2158 5.40796H13.5211C12.8136 5.40796 12.4599 5.40796 12.1897 5.54563C11.952 5.66674 11.7588 5.85998 11.6377 6.09766C11.5 6.36786 11.5 6.72158 11.5 7.42901V11.808C11.5 12.5154 11.5 12.8691 11.6377 13.1393C11.7588 13.377 11.952 13.5702 12.1897 13.6913C12.4599 13.829 12.8136 13.829 13.5211 13.829H15.5"
            stroke="#475467"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 10.25C19 11 19.0004 11.2345 19 11.5C19 12.2494 19 12.5 19 12.5M19 14.25V14"
            stroke="#475467"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5 10.04C15.5 9.32593 15.5 8.9689 15.6431 8.69616C15.7689 8.45626 15.9697 8.26121 16.2166 8.13897C16.4974 8 16.8649 8 17.6 8H20.4C21.1351 8 21.5026 8 21.7834 8.13897C22.0303 8.26121 22.2311 8.45626 22.3569 8.69616C22.5 8.9689 22.5 9.32593 22.5 10.04V14.46C22.5 15.1741 22.5 15.5311 22.3569 15.8038C22.2311 16.0437 22.0303 16.2388 21.7834 16.361C21.5026 16.5 21.1351 16.5 20.4 16.5H17.6C16.8649 16.5 16.4974 16.5 16.2166 16.361C15.9697 16.2388 15.7689 16.0437 15.6431 15.8038C15.5 15.5311 15.5 15.1741 15.5 14.46V10.04Z"
            stroke="#475467"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_29609_4790">
          <rect width="12" height="12" fill="white" transform="translate(11 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
